import { Command } from './Command'
import * as THREE from 'three'

class AddObjectCommand extends Command {
  constructor(editor, object, isImportant = false, ani = []) {
    super(editor)
    this.type = 'AddObjectCommand'
    this.object = object
    this.isImportant = isImportant
    this.ani = []
    if (object !== undefined) {
      this.name = `添加对象：${object.name}`
    }
    if (ani.length > 0) {
      this.ani = ani
    }
  }
  setAnimaition(ani) {
    if (ani.length > 0) {
      const _ani = ani.filter(
        (itemA) =>
          !this.editor.animations.some((itemB) => itemB.name === itemA.name),
      )
      this.editor.animations.push(..._ani)
      this.editor.animationControl.initAddAnimation(_ani)
    }
  }
  execute() {
    this.editor.addObject(this.object)
    if (this.isImportant) {
      this.setAnimaition(this.ani)
      this.editor.importantFile(this.object)
    }
    this.editor.select(this.object)
  }
  // 撤销执行的操作
  undo() {
    this.editor.removeObject(this.object)
    if (this.isImportant) {
      this.editor.removeImportantFile(this.object, this.ani)
    }
    this.editor.deselect()
  }
  toJSON() {
    const output = super.toJSON(this)
    output.object = this.object.toJSON()
    return output
  }
  fromJSON(json) {
    this.fromJSON(json)
    this.object = this.editor.objectByUuid(json.object.object.uuid)
    if (this.object === undefined) {
      const loader = new THREE.ObjectLoader()
      this.object = loader.parse(json.object)
    }
  }
}

export { AddObjectCommand }
