/**
 * 预览-渲染标题
 */
import { UIDiv, UIIcon, UISpan } from './Ui'

function PreviewTitle(editor) {
  let ifFirst = true
  let firstObj = null
  let signals = editor.signals
  let container = new UIDiv().setClass('title-box')
  let iconDom = new UIIcon('icon-icon-test7')
  iconDom.onClick(() => {
    console.log('页面回调')
  })
  container.add(iconDom)
  let title = new UISpan()
  container.add(title)
  function setContent(object) {
    let baseContent = object?.userData?.base
    if (baseContent && baseContent.title) {
      title.setTextContent(baseContent.title)
      container.setDisplay('')
    } else {
      title.setTextContent('')
      container.setDisplay('none')
    }
  }

  signals.sceneGraphChanged.add(() => {
    if (ifFirst) {
      ifFirst = false
      setContent(firstObj)
    }
  })

  signals.objectSelected.add((object) => {
    if (object && object.isGroup) {
      firstObj = object
    }
    if (object) {
      setContent(object)
    }
  })

  return container
}
export { PreviewTitle }
