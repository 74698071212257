/**
 * 预览-渲染标题-手机端
 */
import { UIDiv, UIIcon, UISpan } from './Ui'

function PreviewTitlePhone(editor) {
  let ifFirst = true
  let firstObj = null
  let signals = editor.signals
  let container = new UIDiv().setClass('title-box-phone')

  let title = new UISpan().setClass('title-box-phone-name noShow')
  container.add(title)
  function setContent(object) {
    let baseContent = object?.userData?.base
    if (baseContent && baseContent.title) {
      title.setTextContent(baseContent.title)
      title.removeClass('noShow')
    } else {
      title.setTextContent('')
      title.addClass('noShow')
    }
  }

  signals.sceneGraphChanged.add(() => {
    if (ifFirst) {
      ifFirst = false
      setContent(firstObj)
    }
  })

  signals.objectSelected.add((object) => {
    if (object && object.isGroup) {
      firstObj = object
    }
    if (object) {
      setContent(object)
    }
  })

  return container
}
export { PreviewTitlePhone }
