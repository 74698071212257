import axiosRequest from '../../index'
import Api from '../../api'
import type {
  LoginType,
  CaptchaType,
  RegisterType,
  ForgetPwdType,
  IUpdatePwdData,
} from './types'
// import '@/mock/http/login.mock'

// 登录
export const httpLogin = (data: LoginType) =>
  axiosRequest.post({
    url: Api.user.login,
    data,
    isToken: false,
  })

// 获取注册验证码
export const httpRegisterCaptcha = (data: { phoneNumber: string | number }) =>
  axiosRequest.post({
    url: Api.user.registerCaptcha,
    data,
    isToken: false,
  })
// 忘记密码验证码
export const httpPwdCaptcha = (data: { phoneNumber: string | number }) =>
  axiosRequest.post({
    url: Api.user.forgetCaptcha,
    data,
    isToken: false,
  })

// 获取验证码
export const httpCaptcha = (data: CaptchaType) =>
  axiosRequest.post({
    url: Api.user.captcha,
    data,
    isToken: false,
  })

// 注册
export const httpRegister = (data: RegisterType) =>
  axiosRequest.post({
    url: Api.user.register,
    data,
    isToken: false,
  })

// 忘记密码
export const httpForgetPwd = (data: ForgetPwdType) =>
  axiosRequest.post({
    url: Api.user.forgetPwd,
    data,
    isToken: false,
  })

// 退出登录
export const httpGoout = () =>
  axiosRequest.get({
    url: Api.user.goout,
  })

// 修改密码
export const httpPutPwd = (data: IUpdatePwdData) =>
  axiosRequest.put({
    url: Api.user.putPwd,
    data,
  })

// 生成邀请链接
export const httpCreateLink = (params: { validInDays: number }) =>
  axiosRequest.get({
    url: Api.user.createLink,
    params,
  })

// 加入单位
export const httpJoin = (params: { signature: string }) =>
  axiosRequest.get({
    url: Api.user.join,
    params,
    isShowError: false,
  })

// 退出单位
export const httpQuit = () =>
  axiosRequest.put({
    url: Api.user.exit,
  })
