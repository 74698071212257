import { Modal } from 'ant-design-vue'
import store from '@/store/index'
import { OSS_VISIT_URL, ERROR_IMG } from '@/config/index'
interface Iobj {
  [x: string]: any
}
/**
 * 筛选参数
 * @param {Object} obj
 */
export const screenObj = (obj: Iobj) => {
  const _obj: Iobj = {}
  for (const key in obj) {
    if (
      typeof obj[key] === 'boolean' ||
      (obj[key] !== undefined && obj[key] !== null)
    )
      _obj[key] = obj[key]
  }
  return _obj
}
/**
 * 翻页
 * @param {Object} pagination
 * {total, pageSize, current} = pagination
 */
export const setPage = (pagination: {
  total: number
  pageSize: number
  current: number
}) => {
  const page = Math.ceil(pagination.total / pagination.pageSize)
  if (page < pagination.current) {
    return page
  } else {
    return pagination.current
  }
}
/**
 * 对象赋值
 * @param {Object} obj1
 * @param {Object} obj2
 */
interface ObjProps {
  [key: string]: string
}
export const filterKeys = (obj1: object, obj2: object) => {
  if (obj1 instanceof Object && obj2 instanceof Object) {
    Object.keys(obj1).forEach((key: string) => {
      ;(obj1 as ObjProps)[key] = (obj2 as ObjProps)[key]
    })
  }
}

export const HMconfirm = (
  content: any,
  onOk?: () => void,
  onCancel?: () => void,
) => {
  return Modal.confirm({
    title: '确认',
    okText: '确定',
    cancelText: '取消',
    content,
    onOk: async () => {
      if (onOk) {
        await onOk()
      }
    },
    onCancel() {
      onCancel && onCancel()
    },
  })
}

export const HMConfirmBack = (content: any, arg: any[]) => {
  const model = Modal.confirm({
    title: '确认',
    okText: '确定',
    cancelText: '取消',
    content,
    ...arg,
  })
  return model
}

// 获取文件后缀
export const getFileSuffix = (name: string) => {
  if (!name) return ''
  const fileNameIndex = name.lastIndexOf('.')
  const suffix = name.substring(fileNameIndex + 1, name.length)
  return suffix
}
/**
 * 模拟点击登录
 * @param {HTMLElement} element - dom节点
 * @param {number} x - 鼠标x轴偏移量
 * @param {number} y - 鼠标y轴偏移量
 */
const simulateClick = (element: HTMLElement, x: number, y: number) => {
  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
    clientX: x,
    clientY: y,
  })

  element.dispatchEvent(event)
}

// 验证某个操作是否要先登录
export const checkLogin = (callback: () => void) => {
  if (store.state.token) {
    callback && callback()
  } else {
    const loginDom = document.querySelector('.login-btn') as HTMLElement
    simulateClick(loginDom, loginDom.offsetLeft, loginDom.offsetHeight)
  }
}

// 获取对象值
export const getEnumValue = (enumType: object, type: string) => {
  const _arr = Object.entries(enumType).find(([key, value]) => value === type)
  if (_arr?.length) {
    return _arr[0]
  }
  return ''
}

// 面包屑做特殊处理
export const breadcrumbSpecial = (list: any[], type: string) => {
  if (list.length) {
    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element.name === 'ResourceView') {
        element.meta.title = '资源商店' + '(' + type + ')'
      }
    }
  }
}

export const checkType = (item: any) => {
  if (!item) return false
  const fileNameIndex = item.fileName.lastIndexOf('.')
  const fileType = item.fileName.substring(fileNameIndex, item.fileName.length)
  return ['.mp4'].includes(fileType)
}

export const checkTypeFileName = (name: string) => {
  if (!name) return false
  const fileNameIndex = name.lastIndexOf('.')
  const fileType = name.substring(fileNameIndex, name.length)
  return ['.mp4'].includes(fileType)
}

export const book = (record: any) => {
  const _value = record.exhibitUrl
  const isTypeVideo = checkTypeFileName(_value)
  if (isTypeVideo) {
    return (
      OSS_VISIT_URL +
      _value +
      '?x-oss-process=video/snapshot,t_3000,f_jpg,w_1600,h_900,m_fast'
    )
  } else if (_value) {
    return OSS_VISIT_URL + _value
  } else {
    return ERROR_IMG
  }
}

export const book2 = (value: any, other?: boolean) => {
  const isTypeVideo = checkType(value)
  if (isTypeVideo) {
    if (other) {
      return (
        OSS_VISIT_URL +
        value.filePath +
        `?x-oss-process=video/snapshot,t_16000,f_jpg,w_1920,h_994,m_fast`
      )
    } else {
      return OSS_VISIT_URL + value.filePath
    }
  } else if (value && value.filePath) {
    return OSS_VISIT_URL + value.filePath
  } else {
    return ERROR_IMG
  }
}

// 获取枚举key
export function getKeyByValue<T extends string | number>(
  enumObj: Record<string, T>,
  value: T,
): keyof typeof enumObj | undefined {
  const keys = Object.keys(enumObj) as Array<keyof typeof enumObj>
  const foundKey = keys.find((key) => enumObj[key] === value)
  return foundKey
}

// 时间转换 几分钟前 几天前 几周前 几月前
export function switchDate(dateStr: any) {
  const dateTimeStamp = Date.parse(dateStr.replace(/-/gi, '/'))
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const halfamonth = day * 15
  const month = day * 30

  const now = new Date().getTime()
  const diffValue = now - dateTimeStamp
  if (diffValue < 0) {
    //若日期不符则弹出窗口告之
    //alert("结束日期不能小于开始日期！");
    console.log('刚刚')
  }
  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute
  const secC = diffValue / (minute / 60)
  let result
  if (monthC >= 1) {
    if (monthC > 12) {
      result = '更新于' + parseInt((monthC / 12).toString()) + '年前'
    } else {
      result = '更新于' + parseInt(monthC.toString()) + '个月前'
    }
  } else if (weekC >= 1) {
    result = '更新于' + parseInt(weekC.toString()) + '周前'
  } else if (dayC >= 1) {
    result = '更新于' + parseInt(dayC.toString()) + '天前'
  } else if (hourC >= 1) {
    result = '更新于' + parseInt(hourC.toString()) + '个小时前'
  } else if (minC >= 1) {
    result = '更新于' + parseInt(minC.toString()) + '分钟前'
  } else if (secC >= 1) {
    result = '更新于' + parseInt(secC.toString()) + '秒前'
  } else {
    result = '刚刚'
  }
  return result
}

export const getTreeParent = (
  node: string | any[],
  val: any,
  path: any[],
  key = 'id',
  children = 'children',
) => {
  if (!node) return []
  for (const data of node) {
    path.push(data[key])
    if (data[key] === val) return path
    if (data[children]) {
      const findChildren: any[] = getTreeParent(
        data[children],
        val,
        path,
        key,
        children,
      )
      if (findChildren?.length) return findChildren
    }
    path.pop()
  }
  return []
}

// 函数用于将十六进制颜色转为rgba格式
export const hexToRGBA = (hex: string, opacity: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

/**
 * 根据文件后缀获取文件类型
 */

export const getFileType = (fileName: string) => {
  const typeArr = [
    {
      type: 0,
      txt: '其他类型，不做预览',
      value: [],
    },
    {
      type: 1,
      txt: '文档类型',
      value: ['.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.pdf'],
    },
    {
      type: 2,
      txt: '音乐',
      value: ['.mp3'],
    },
    {
      type: 3,
      txt: '视频',
      value: ['.mp4'],
    },
  ]
  if (!fileName) return 0
  const _fileNameIndex = fileName.lastIndexOf('.')
  const _fileType = fileName.substring(_fileNameIndex, fileName.length)
  const _fileTypeObj = typeArr.find(
    (item) => item.value.findIndex((i) => i === _fileType) >= 0,
  )
  if (_fileTypeObj) {
    return _fileTypeObj.type
  }
}

// 检查创建人是否为本人
export function checkIsSelf(id: number) {
  return id == store.state.userInfo?.id
}

export function isObject(value: object) {
  const type = typeof value
  return value !== null && (type === 'object' || type === 'function')
}
