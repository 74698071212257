import { httpOssToken } from '@/http/service/model/index'
import OSS from 'ali-oss'

const API = {
  oss: 'project/file_upload',
}

const OSS_URL = 'http://web3d-arseek.oss-cn-chengdu.aliyuncs.com'

const getQueryVariable = (variable) => {
  //获取参数id
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

export const getHttpUrl = () => {
  const id = getQueryVariable('id')
  // const token = getQueryVariable("token");
  // let _url = "";
  // if (process.env.NODE_ENV === "development") {
  //   _url = "/api";
  // } else {
  //   _url = process.env.VUE_APP_HTTP;
  // }
  return {
    id,
    // token: "Bearer " + token,
    // url: _url,
  }
}

export default {
  globalClient: null,
  abortCheckpoint: null, // 中断点
  async getSts() {
    return new Promise((resolve, reject) => {
      httpOssToken()
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setClient(sts) {
    const _client = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: 'oss-cn-chengdu',
      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: sts.accessKeyId,
      accessKeySecret: sts.accessKeySecret,
      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: sts.securityToken,
      refreshSTSToken: async () => {
        // 向您搭建的STS服务获取临时访问凭证。
        const info = await this.getSts()
        return {
          accessKeyId: info.accessKeyId,
          accessKeySecret: info.accessKeySecret,
          stsToken: info.securityToken,
        }
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 300000,
      // 填写Bucket名称。
      bucket: sts.bucket,
    })
    return _client
  },
  // 直传
  async upload(sts, file, ossDir) {
    const client = this.setClient(sts)
    this.globalClient = client
    this.abortCheckpoint = null
    return new Promise((resolve, reject) => {
      this.globalClient
        .put(ossDir + '/' + file.name, file)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 分片上传
  async multipartUpload(sts, file, ossDir, rename, partSize, progressCallback) {
    const client = this.setClient(sts)
    this.globalClient = client
    this.abortCheckpoint = null
    return new Promise((resolve, reject) => {
      this.globalClient
        .multipartUpload(ossDir + '/' + rename, file, {
          progress: (p, checkpoint) => {
            this.abortCheckpoint = checkpoint
            progressCallback && progressCallback(p, checkpoint)
            if (p === 1) {
              this.abortCheckpoint = null
            }
          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 取消分片上传
  // cacelMultipartUpload() {
  //   return new Promise((resolve) => {
  //     if (this.abortCheckpoint) {
  //       HMconfirm(
  //         '您选择的文件正在上传中，确定取消吗',
  //         () => {
  //           this.globalClient.abortMultipartUpload(
  //             this.abortCheckpoint.name,
  //             this.abortCheckpoint.uploadId,
  //           )
  //           this.abortCheckpoint = null
  //           resolve(true)
  //         },
  //         () => {
  //           resolve(false)
  //         },
  //       )
  //     } else {
  //       resolve(true)
  //     }
  //   })
  // },
}
