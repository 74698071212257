/**
 * 预览-右边控制面板显示操作
 */

import { UIDiv, UISpan } from './Ui'
import { SetObjectPanAnimationCommand } from './commands/SetObjectPanAnimationCommand'

function PreviewControl(editor) {
  let signals = editor.signals
  let step = 0.5
  const container = new UIDiv().setClass('panel-box')
  // 缩放大小面板展示
  const scaleDom = new UIDiv().setClass('zoom-box')
  // 大小值
  let scaleNum = 100
  const scaleNumDom = new UIDiv()
    .setClass('zoom-num')
    .setTextContent(`${scaleNum}%`)

  function setContent(num) {
    scaleNumDom.setTextContent(`${num}%`)
  }
  const scaleNumEnlargeDom = new UIDiv().setClass('add-icon')
  scaleNumEnlargeDom.onClick(() => {
    console.log('点击了加号')
  })
  const scaleNumNarrowDom = new UIDiv().setClass('minus-icon')
  scaleNumNarrowDom.onClick(() => {
    console.log('点击了减号')
  })
  const barBoxDom = new UIDiv().setClass('bar')
  const viewNumDom = barBoxDom.add()
  const barDom = new UIDiv().setClass('active-bar')

  function setBarPosition(num) {
    // console.log(num, '22')
    // barDom.setStyle(
    //   "height",
    //   Math.floor((barBoxDom.dom.clientHeight / num) * 100) + "px"
    // );
  }

  viewNumDom.add(barDom)
  scaleDom.add(scaleNumDom, scaleNumEnlargeDom, viewNumDom, scaleNumNarrowDom)
  // 模型位置偏移面板
  const panCameraDom = new UIDiv().setClass('pan-camera')
  const leftCameraDom = new UISpan().setClass('btn-icon').addClass('left')
  const rightCameraDom = new UISpan().setClass('btn-icon').addClass('right')
  const topCameraDom = new UISpan().setClass('btn-icon').addClass('top')
  const bottomCameraDom = new UISpan().setClass('btn-icon').addClass('bottom')
  const resetCameraDom = new UISpan().setClass('radius').addClass('reset')

  panCameraDom.onTouchStart((e) => {
    e.preventDefault()
    if (e.target.localName.toLowerCase() === 'span') {
      const controlsFun = new SetObjectPanAnimationCommand(editor, step)
      let eventName = e.target.className.split(' ').pop()
      controlsFun.execute(eventName)
      panCameraDom.onTouchEnd(() => {
        controlsFun.execute('clear')
      })
    }
  })

  panCameraDom.onMouseDown((e) => {
    e.preventDefault()
    if (e.target.localName.toLowerCase() === 'span') {
      const controlsFun = new SetObjectPanAnimationCommand(editor, step)
      let eventName = e.target.className.split(' ').pop()
      controlsFun.execute(eventName)
      panCameraDom.onMouseUp(() => {
        controlsFun.execute('clear')
      })
    }
  })

  panCameraDom.add(
    leftCameraDom,
    rightCameraDom,
    topCameraDom,
    bottomCameraDom,
    resetCameraDom,
  )

  // 动画面板
  const animationBtn = new UIDiv()
    .setTextContent('动画')
    .addClass('animation-btn')
    .setDisplay('none')
  animationBtn.onClick(() => {
    signals.showPhoneAnimationBtn.dispatch()
  })

  signals.setCameraZoom.add((num) => {
    setContent(num)
    setBarPosition(num)
  })

  signals.sceneGraphChanged.add(() => {
    if (
      editor.json &&
      editor.json.animations &&
      editor.json.animations.length
    ) {
      animationBtn.setDisplay('')
    } else {
      animationBtn.setDisplay('none')
    }
  })

  // container.add(scaleDom, panCameraDom, animationBtn)
  container.add(panCameraDom, animationBtn)

  return container
}
export { PreviewControl }
