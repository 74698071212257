/**
 * 预览-渲染底部操作按钮栏
 */
import { message } from 'ant-design-vue'
import { UIDiv, UIIcon, UISpan } from './Ui'
import { useStore } from 'vuex'

/**
 * 模型不描边时，不显示底部操作
 * 点击模型选中，若没有子元素，则显示组合，观察
 * 点击模型选中，如有子元素，无父元素，则显示拆分，观察
 * 点击模型选中，如有子元素，有父元素，则显示拆分，组合，观察
 */

function PreviewBottomBtnPc(editor) {
  // 是否可操作（是否可点击）
  const store = useStore()
  let ws = null
  let btnStates = new WeakMap()
  let signals = editor.signals
  let scene = editor.scene
  let isActive = false // 是否选中模型
  let selectedObject = null

  // 判断是否包含
  const checkNode = (dom) => {
    return btnStates.has(dom)
  }
  const setNode = (dom, isClick) => {
    btnStates.set(dom, isClick)
  }
  const getNode = (dom) => {
    return btnStates.get(dom)
  }

  const container = new UIDiv().setClass('bottom-box-pc')
  // 操作面板
  const operatePanel = new UIDiv().setClass('description-pc')
  const zuheBtn = new UISpan().setClass('zuhe-btn').setTextContent('组合')
  zuheBtn.onClick(() => {
    if (zuheBtn.hasClass('hover')) {
      signals.composeAnimation.dispatch()
      if (store?.state?.WS) {
        ws = store.state.WS
      }
      if (ws !== null) {
        ws['rti'].sendRti(94)
      }
    }
  })
  const chaifenBtn = new UISpan().setClass('chaifen-btn').setTextContent('拆分')
  chaifenBtn.onClick(() => {
    if (chaifenBtn.hasClass('hover')) {
      signals.decomposeAnimation.dispatch()
      if (store?.state?.WS) {
        ws = store.state.WS
      }
      if (ws !== null) {
        ws['rti'].sendRti(93)
      }
    }
  })
  const zhishidianBtn = new UISpan()
    .setClass('zhishidian-btn')
    .setTextContent('知识点')
  zhishidianBtn.onClick(function () {
    if (!this.hasClass('hover')) return false
    if (this.hasClass('active')) {
      this.removeClass('active')
      knowledgePanel.setDisplay('none')
    } else {
      this.addClass('active')
      knowledgePanel.setDisplay('')
    }
  })
  signals.handleZhishidianBtn.add((msg) => {
    // 打开知识点
    if (!zhishidianBtn.hasClass('hover')) return false
    if (msg === 101) {
      zhishidianBtn.addClass('active')
      knowledgePanel.setDisplay('')
    } else if (msg === 102) {
      zhishidianBtn.removeClass('active')
      knowledgePanel.setDisplay('none')
    }
  })
  const guancaBtn = new UISpan().setClass('guanca-btn').setTextContent('观察')
  guancaBtn.onClick(() => {
    if (guancaBtn.hasClass('hover')) {
      signals.watchAnimation.dispatch()
    }
  })
  const resetBtn = new UISpan().setClass('reset-btn')
  resetBtn.onClick(() => {
    signals.composeAnimationAll.dispatch()
    if (store?.state?.WS) {
      ws = store.state.WS
    }
    if (ws !== null) {
      ws['rti'].sendRti(105)
    }
  })
  // 给各按钮绑定状态值
  if (!checkNode(zhishidianBtn)) {
    setNode(zhishidianBtn, true)
    zhishidianBtn.addClass('hover')
  }

  operatePanel.add(zuheBtn, chaifenBtn, resetBtn, zhishidianBtn, guancaBtn)
  container.add(operatePanel)

  // 知识点面板-标题
  const knowledgePanel = new UIDiv().setClass('knowledge-panel')
  knowledgePanel.setDisplay('none')
  const knowledgeTitlePanel = new UIDiv()
    .setTextContent('知识点')
    .setClass('knowledge-title')
  // -------------------------循环子面板 start-----------------------------------------------------------------
  // 创建知识点子面板
  const createknowledgeEveryPanelDom = (element, i) => {
    // 每个知识点面板
    const knowledgeEveryPanel = new UIDiv().setClass(
      `knowledge-panel-every noShow knowledge-panel-every-${(i % 3) + 1}`,
    )
    // 每个知识点面板-标题
    const knowledgeEveryTitlePanel = new UIDiv()
      .setClass('title')
      .setTextContent(element.other.title)
    // 每个知识点面板-描述
    const knowledgeEveryDesPanel = new UIDiv()
      .setClass('des')
      .setTextContent(element.other.description)
    // 快速定位图标
    const knowledgeEveryPositionPanel = new UISpan().setClass('position')
    knowledgeEveryPositionPanel.onClick(() => {
      // 点击事件
      if (desPanel) {
        knowledgePanel.remove(desPanel)
        desPanel = null
      } else {
        knowledgePanel.add(desPanelDom(element.other, (i % 3) + 1))
      }
    })
    knowledgeEveryPanel.add(
      knowledgeEveryTitlePanel,
      knowledgeEveryDesPanel,
      knowledgeEveryPositionPanel,
    )
    return knowledgeEveryPanel
  }

  signals.handleKnowledgeEveryPosition.add((state, name) => {
    if (state) {
      //
    }
  })

  // 创建描述详细信息面板
  let desPanel = null
  let positionDom = null
  const desPanelDom = (element, i) => {
    desPanel = new UIDiv().setClass(
      `knowledge-panel-every-desbox knowledge-panel-every-desbox-${i}`,
    )
    const titleDiv = new UIDiv().setClass('title').setTextContent(element.title)
    const desDiv = new UIDiv()
      .setClass('des')
      .setTextContent(element.description)
    positionDom = new UISpan().setClass('position')
    // 点击移除自身面板
    positionDom.onClick(() => {
      knowledgePanel.remove(desPanel)
      desPanel = null
    })
    desPanel.add(titleDiv, desDiv, positionDom)
    return desPanel
  }
  // -------------------------循环子面板 end-----------------------------------------------------------------

  // --------------------------知识点面板外层盒子 start----------------------------------------------------
  const knowledgeBoxBigPanel = new UIDiv().setClass('knowledge-box-panel-big')
  const knowledgeBoxPanel = new UIDiv().setClass('knowledge-box-panel')
  knowledgeBoxBigPanel.add(knowledgeBoxPanel)
  // 外层盒子实现拖拽滚动
  var flag //鼠标按下
  var downY //鼠标点击的Y下标
  var scrollTop //当前元素滚动条的偏移量
  knowledgeBoxPanel.dom.addEventListener('mousedown', function (event) {
    flag = true
    downY = event.clientY //获取到点击的Y下标
    scrollTop = this.scrollTop //获取当前元素滚动条的偏移量
  })
  knowledgeBoxPanel.dom.addEventListener('mousemove', function (event) {
    if (flag) {
      //判断是否是鼠标按下滚动元素区域
      var moveY = event.clientY //获取移动的Y轴
      var scrolly = moveY - downY //当前移动的Y轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
      this.scrollTop = scrollTop - scrolly //鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
      console.log(scrolly)
    }
  })
  // 鼠标抬起停止拖动
  knowledgeBoxPanel.dom.addEventListener('mouseup', function () {
    flag = false
  })
  // 鼠标离开元素停止拖动
  knowledgeBoxPanel.dom.addEventListener('mouseleave', function (event) {
    flag = false
  })
  // --------------------------知识点面板外层盒子 end-----------------------------------------------------

  // 创建未选中时，知识点不展示的提示盒子
  let noneDataDom = null
  let txtDom = null
  const noneDataText1 = '当前未选中模型'
  const noneDataText2 = '当前模型暂无知识点'
  const knowledgeNoneDataPanel = () => {
    if (knowledgeBoxBigPanel.dom.children.length >= 2) return
    noneDataDom = new UIDiv().setClass('none-data-box')
    const noneDataIconDom = new UIDiv().setClass('none-data-icon')
    txtDom = new UIDiv().setClass('none-data-txt').setTextContent(noneDataText1)
    noneDataDom.add(noneDataIconDom, txtDom)
    knowledgeBoxBigPanel.add(noneDataDom)
  }
  // 手动组装知识点数据
  const childPanelWeak = new WeakMap()
  console.log(childPanelWeak, '123')
  const getKnowledge = () => {
    scene = editor.scene
    let i = 0
    scene.traverse((child) => {
      if (child.userData && child.userData.base) {
        if (
          child.userData.base.title.length !== 0 &&
          child.userData.base.description.length !== 0
        ) {
          const _child = {
            uuid: child.uuid,
            other: child.userData.base,
            isShow: true,
            name: child.name,
          }
          const _node = createknowledgeEveryPanelDom(_child, i)
          knowledgeBoxPanel.add(_node)
          childPanelWeak.set(child, {
            dom: _node,
            ..._child,
          })
          i++
        }
      }
    })
  }

  knowledgePanel.add(knowledgeTitlePanel, knowledgeBoxBigPanel)
  container.add(knowledgePanel)

  // -----------------------------------分割一下----------------------------------------------------------
  // 设置知识点面板的状态
  let hasKnowledge = false
  function checkCreateKnowledge() {
    const allNode = scene
    hasKnowledge = false
    allNode.traverse((child) => {
      const _child = childPanelWeak.get(child)
      if (_child) {
        if (selectedObject && _child.uuid === selectedObject.uuid && isActive) {
          _child.dom.removeClass('noShow')
          hasKnowledge = true
        } else if (
          selectedObject &&
          isActive &&
          selectedObject.parent.isScene
        ) {
          _child.dom.removeClass('noShow')
          hasKnowledge = true
        } else {
          _child.dom.addClass('noShow') // 展示
        }
      }
    })
    if (isActive) {
      // 判断是否有知识点展示，没有则提示-当前模型暂无知识点
      if (hasKnowledge) {
        noneDataDom.addClass('noShow') // 不展示
      } else {
        txtDom.setTextContent(noneDataText2)
        noneDataDom.removeClass('noShow')
      }
    } else {
      txtDom?.setTextContent(noneDataText1)
      noneDataDom?.removeClass('noShow')
    }
  }

  // 设置模型在选中状态下按钮对应的显隐状态
  function setShowAndHideState() {
    chaifenBtn.removeClass('hover')
    guancaBtn.removeClass('hover')
    zuheBtn.removeClass('hover')
    if (selectedObject && selectedObject.children.length > 0) {
      chaifenBtn.addClass('hover')
    }
    if (selectedObject && !selectedObject.parent.isScene) {
      zuheBtn.addClass('hover')
      guancaBtn.addClass('hover')
    }
  }

  signals.sceneGraphChanged.add(() => {
    if (
      editor.json &&
      editor.json.animations &&
      editor.json.animations.length
    ) {
      container.setDisplay('none')
    } else {
      container.setDisplay()
      getKnowledge()
      knowledgeNoneDataPanel()
    }
  })

  signals.objectSelected.add((object) => {
    selectedObject = object
    if (object !== null) {
      isActive = true
    } else {
      isActive = false
    }
    positionDom && positionDom.dom.click()
    setShowAndHideState()
    checkCreateKnowledge()
  })

  return container
}
export { PreviewBottomBtnPc }
