import { UIDiv, UISpan } from '../../Ui'
import { UIRow } from '../../Ui.three'
import { RemoveObjectCommand } from '../../commands/RemoveObjectCommand'
import { AddObjectCommand } from '../../commands/AddObjectCommand'

function shrinkPanelFun(dom, callback) {
  let children = dom.parentNode.parentNode.getElementsByClassName('items')
  function setChildStyle(children, state) {
    let arr = Array.from(children)
    let newArr = []
    if (state === 'none') {
      newArr = arr.filter(
        (item) => Array.from(item.classList).indexOf('force') === -1,
      )
    } else {
      // 展示的时候
      newArr = arr.filter(
        (item) =>
          Array.from(item.classList).indexOf('force') === -1 &&
          (item.getAttribute('data-show') === null ||
            item.getAttribute('data-show') === '1'),
      )
    }
    newArr.forEach((item) => (item.style.display = state))
  }
  if (dom.className.indexOf('closed') < 0) {
    dom.classList.add('closed')
    setChildStyle(children, 'none')
  } else {
    dom.classList.remove('closed')
    setChildStyle(children, '')
  }
}

function isMobile() {
  const mobilePattern =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  return mobilePattern.test(navigator.userAgent)
}

function isMouseInArea(area) {
  const e = new Event('mouseevent')
  const rect = area.getBoundingClientRect()
  return (
    e.clientX >= rect.left &&
    e.clientX <= rect.right &&
    e.clientY >= rect.top &&
    e.clientY <= rect.bottom
  )
}

// 右键菜单
const menuTxtDelete = {
  name: '删除帧',
  clickName: 'Delete',
  callback: (editor) => {
    editor.animationControl.deleteKeyFrame()
  },
}
const menuTxtUndo = {
  name: '撤回上一步',
  clickName: 'Ctrl+Z',
  callback: (editor) => {
    editor.undo()
  },
}
// 复制场景对象
const copyModleItem = {
  name: '创建副本',
  clickName: 'Ctrl+D',
  callback: (editor) => {
    if (editor.selected === null) return
    const _copyObj = editor.selected.clone()
    _copyObj.name = `${_copyObj.name}copy`
    editor.execute(new AddObjectCommand(editor, _copyObj))
  },
}
// 删除场景中选中的对象
const deleteModleItem = {
  name: '删除',
  clickName: 'Delete',
  callback: (editor) => {
    if (editor.selected !== null) {
      editor.execute(new RemoveObjectCommand(editor, editor.selected))
    }
  },
}
// 删除动画列表项
const deleteAnimationItem = {
  name: '删除',
  clickName: 'Delete',
  callback: null,
}

// 创建动画副本项
const copyAnimationItem = {
  name: '创建副本',
  clickName: 'Ctrl+D',
  callback: null,
}

function createCustomMenu(event, ...menuTxtAttr) {
  const clickX = event.clientX
  const clickY = event.clientY

  const customMenu = new UIRow().addClass('custom-menu')
  menuTxtAttr.forEach((item) => {
    const _option = new UIDiv().setClass('flex').addClass('flex-j-b')
    const _spanTxt = new UISpan().setTextContent(item.name)
    _option.add(_spanTxt)
    if (item.clickName) {
      const _spanClickName = new UISpan()
        .setTextContent(item.clickName)
        .setClass('gray-color')
      _option.add(_spanTxt, _spanClickName)
    }
    _option.onClick(function (event) {
      item.callback(editor)
      removeCustomMenu(event, true)
    })
    customMenu.add(_option)
  })
  customMenu.dom.style.left = `${clickX + 10}px`
  customMenu.dom.style.top = `${clickY}px`
  document.body.appendChild(customMenu.dom)

  function removeCustomMenu(event, isHand) {
    if (!customMenu.dom.contains(event.target) || isHand === true) {
      document.body.removeChild(customMenu.dom)
      document.removeEventListener('mousedown', removeCustomMenu)
    }
  }
  document.addEventListener('mousedown', removeCustomMenu)
}

/**
 * 节流
 * @param {回调} fn
 * @param {间隔时间} time
 */
const throttle = (fn, time) => {
  let lastTime = 0
  return (...args) => {
    const nowTime = new Date().getTime()
    const remainTime = time - (nowTime - lastTime)
    if (remainTime <= 0) {
      console.log(1111111111)
      fn.apply(this, args)
      lastTime = nowTime
    }
  }
}

export {
  shrinkPanelFun,
  isMobile,
  createCustomMenu,
  menuTxtDelete,
  menuTxtUndo,
  copyModleItem,
  deleteModleItem,
  deleteAnimationItem,
  copyAnimationItem,
  isMouseInArea,
  throttle,
}
