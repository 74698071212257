import * as THREE from 'three'
import { AddObjectCommand } from './commands/AddObjectCommand'
import { UIDiv, UIText, UIInput, UISpan } from './Ui'

function initBoxGeometry(editor) {
  let geometry = new THREE.BoxGeometry(1, 1, 1, 1, 1, 1)
  let mesh = new THREE.Mesh(geometry, new THREE.MeshStandardMaterial())
  mesh.name = 'Box'
  editor.execute(new AddObjectCommand(editor, mesh))
}
class UIRow extends UIDiv {
  constructor() {
    super()
    this.dom.className = 'list'
  }
  add() {
    for (let i = 0; i < arguments.length; i++) {
      const argument = arguments[i]
      // if (argument instanceof UIDiv) {
      //   this.dom.appendChild(argument.dom)
      //   this.setNotFirst()
      // } else {
      //   console.log('UIElement', argument, 'is not an instance of UIElement.')
      // }
      this.dom.appendChild(argument.dom)
      this.setNotFirst()
    }
    return this
  }
  setNotFirst() {
    if (this.dom.children.length > 1) {
      let child = this.dom.children
      for (let i = 1; i < child.length; i++) {
        child[i].classList.add('items')
      }
    }
  }
}

class UIRowItem extends UIDiv {
  constructor(label) {
    super()
    this.dom.className = 'flex'
    if (label == undefined) {
      this.add(new UISpan())
    }
    if (label !== undefined && typeof label == 'string') {
      let child = new UIText(label)
      this.add(child)
    }
    if (label !== undefined && typeof label == 'object') {
      this.add(label)
    }
  }
}

class UIInputNumber extends UIInput {
  constructor(number) {
    super(number)
    this.value = 0
    this.dom.value = 0.0
    this.min = -Infinity
    this.max = Infinity
    this.precision = 2
    this.step = 1
    this.unit = ''

    let distance = 0
    let onMouseDownValue = 0

    let scope = this

    const pointer = { x: 0, y: 0 }
    const prevPointer = { x: 0, y: 0 }

    this.setValue(number)

    let changeEvent = new Event('change', {
      bubbles: true,
      cancelable: true,
    })

    function onMousedown(event) {
      event.preventDefault()
      distance = 0
      onMouseDownValue = scope.value

      prevPointer.x = event.clientX
      prevPointer.y = event.clientY

      document.addEventListener('mousemove', onMousemove, false)
      document.addEventListener('mouseup', onMouseup, false)
    }

    function onMousemove(event) {
      let currentValue = scope.value

      pointer.x = event.clientX
      pointer.y = event.clientY

      distance += pointer.x - prevPointer.x - (pointer.y - prevPointer.y)

      let value =
        onMouseDownValue + (distance / (event.shiftKey ? 5 : 50)) * scope.step
      value = Math.min(scope.max, Math.max(scope.min, value))

      if (currentValue !== value) {
        scope.setValue(value)
        scope.dom.dispatchEvent(changeEvent)
      }

      prevPointer.x = event.clientX
      prevPointer.y = event.clientY
    }

    function onMouseup() {
      document.removeEventListener('mousemove', onMousemove, false)
      document.removeEventListener('mouseup', onMouseup, false)
    }
    function onChange() {
      scope.setValue(scope.dom.value)
    }
    this.dom.addEventListener('mousedown', onMousedown, false)
    this.dom.addEventListener('change', onChange, false)
  }
  setValue(value) {
    if (value !== undefined) {
      value = parseFloat(value)
      if (value < this.min) value = this.min
      if (value > this.max) value = this.max
      this.value = value
      this.dom.value = value.toFixed(this.precision)
      if (this.unit !== '') this.dom.value += ' ' + this.unit
    }
    return this
  }
  setPrecision(precision) {
    this.precision = precision
    return this
  }
  setStep(step) {
    this.step = step
    return this
  }
  setNudge(nudge) {
    this.nudge = nudge
    return this
  }
  setRange(min, max) {
    this.min = min
    this.max = max
    return this
  }
  setUnit(unit) {
    this.unit = unit
    return this
  }
}

class UIOutliner extends UIDiv {
  constructor(editor) {
    super()

    this.dom.className = 'Outliner'
    this.dom.tabIndex = 0 // keyup event is ignored without setting tabIndex

    const scope = this

    // hack
    this.scene = editor.scene

    // Prevent native scroll behavior
    this.dom.addEventListener('keydown', function (event) {
      switch (event.keyCode) {
        case 38: // up
        case 40: // down
          event.preventDefault()
          event.stopPropagation()
          break
      }
    })

    // Keybindings to support arrow navigation
    this.dom.addEventListener('keyup', function (event) {
      switch (event.keyCode) {
        case 38: // up
          scope.selectIndex(scope.selectedIndex - 1)
          break
        case 40: // down
          scope.selectIndex(scope.selectedIndex + 1)
          break
      }
    })

    this.editor = editor

    this.options = []
    this.selectedIndex = -1
    this.selectedValue = null
  }

  selectIndex(index) {
    if (index >= 0 && index < this.options.length) {
      this.setValue(this.options[index].value)

      const changeEvent = document.createEvent('HTMLEvents')
      changeEvent.initEvent('change', true, true)
      this.dom.dispatchEvent(changeEvent)
    }
  }

  setOptions(options) {
    const scope = this

    while (scope.dom.children.length > 0) {
      scope.dom.removeChild(scope.dom.firstChild)
    }

    function onClick() {
      scope.setValue(this.value)

      const changeEvent = document.createEvent('HTMLEvents')
      changeEvent.initEvent('change', true, true)
      scope.dom.dispatchEvent(changeEvent)
    }

    // Drag

    let currentDrag

    function onDrag() {
      currentDrag = this
    }

    function onDragStart(event) {
      event.dataTransfer.setData('text', 'foo')
    }

    function onDragOver(event) {
      if (this === currentDrag) return

      const area = event.offsetY / this.clientHeight

      if (area < 0.25) {
        this.className = 'option dragTop'
      } else if (area > 0.75) {
        this.className = 'option dragBottom'
      } else {
        this.className = 'option drag'
      }
    }

    function onDragLeave() {
      if (this === currentDrag) return

      this.className = 'option'
    }

    function onDrop(event) {
      if (this === currentDrag || currentDrag === undefined) return

      this.className = 'option'

      const scene = scope.scene
      const object = scene.getObjectById(currentDrag.value)

      const area = event.offsetY / this.clientHeight

      if (area < 0.25) {
        const nextObject = scene.getObjectById(this.value)
        moveObject(object, nextObject.parent, nextObject)
      } else if (area > 0.75) {
        let nextObject, parent

        if (this.nextSibling !== null) {
          nextObject = scene.getObjectById(this.nextSibling.value)
          parent = nextObject.parent
        } else {
          // end of list (no next object)

          nextObject = null
          parent = scene.getObjectById(this.value).parent
        }

        moveObject(object, parent, nextObject)
      } else {
        const parentObject = scene.getObjectById(this.value)
        moveObject(object, parentObject)
      }
    }

    function moveObject(object, newParent, nextObject) {
      if (nextObject === null) nextObject = undefined

      let newParentIsChild = false

      object.traverse(function (child) {
        if (child === newParent) newParentIsChild = true
      })

      if (newParentIsChild) return
      const editor = scope.editor
      editor.execute(
        new MoveObjectCommand(editor, object, newParent, nextObject),
      )

      const changeEvent = document.createEvent('HTMLEvents')
      changeEvent.initEvent('change', true, true)
      scope.dom.dispatchEvent(changeEvent)
    }

    //

    scope.options = []

    for (let i = 0; i < options.length; i++) {
      const div = options[i]
      div.className = 'option'
      scope.dom.appendChild(div)

      scope.options.push(div)

      div.addEventListener('click', onClick)

      if (div.draggable === true) {
        div.addEventListener('drag', onDrag)
        div.addEventListener('dragstart', onDragStart) // Firefox needs this

        div.addEventListener('dragover', onDragOver)
        div.addEventListener('dragleave', onDragLeave)
        div.addEventListener('drop', onDrop)
      }
    }

    return scope
  }

  getValue() {
    return this.selectedValue
  }

  setValue(value) {
    for (let i = 0; i < this.options.length; i++) {
      const element = this.options[i]

      if (element.value === value) {
        element.classList.add('active')

        // scroll into view

        const y = element.offsetTop - this.dom.offsetTop
        const bottomY = y + element.offsetHeight
        const minScroll = bottomY - this.dom.offsetHeight

        if (this.dom.scrollTop > y) {
          this.dom.scrollTop = y
        } else if (this.dom.scrollTop < minScroll) {
          this.dom.scrollTop = minScroll
        }

        this.selectedIndex = i
      } else {
        element.classList.remove('active')
      }
    }

    this.selectedValue = value

    return this
  }
}

export { initBoxGeometry, UIRow, UIRowItem, UIInputNumber, UIOutliner }
