class History {
  constructor(editor) {
    this.editor = editor
    this.undos = []
    this.redos = []
    this.lastCmdTime = new Date()
    this.idCounter = 0
    this.historyDisabled = false
    this.config = editor.config
    // this.editor.signals.startPlayer.add(() => {
    //   this.historyDisabled = true;
    // });
    // this.editor.signals.stopPlayer.add(() => {
    //   this.historyDisabled = false;
    // });
  }
  execute(cmd, optionalName) {
    console.log(3333333333)
    const lastCmd = this.undos[this.undos.length - 1]
    const timeDifference = new Date().getTime() - this.lastCmdTime.getTime()
    const isUpdatableCmd =
      lastCmd &&
      lastCmd.updatable &&
      cmd.updatable &&
      lastCmd.object === cmd.object &&
      lastCmd.type === cmd.type &&
      lastCmd.script === cmd.script &&
      lastCmd.attributeName === cmd.attributeName
    if (isUpdatableCmd && cmd.type === 'SetScriptValueCommand') {
      lastCmd.update(cmd)
      cmd = lastCmd
    } else if (isUpdatableCmd && timeDifference < 500) {
      lastCmd.update(cmd)
      cmd = lastCmd
    } else {
      this.undos.push(cmd)
      cmd.id = ++this.idCounter
    }
    cmd.name = optionalName !== undefined ? optionalName : cmd.name
    cmd.execute()
    cmd.inMemory = true
    this.lastCmdTime = new Date()
    this.redos = []
    this.editor.signals.historyChanged.dispatch(cmd)
  }
  undo() {
    let cmd = undefined
    if (this.undos.length > 0) {
      cmd = this.undos.pop()
      if (cmd.inMemory === false) {
        cmd.fromJSON(cmd.json)
      }
    }
    if (cmd !== undefined) {
      cmd.undo()
      this.redos.push(cmd)
      this.editor.signals.historyChanged.dispatch(cmd)
    }
    return cmd
  }
  redo() {
    let cmd = undefined
    if (this.redos.length > 0) {
      cmd = this.redos.pop()
      if (cmd.inMemory === false) {
        cmd.fromJSON(cmd.json)
      }
    }
    if (cmd !== undefined) {
      cmd.execute()
      this.undos.push(cmd)
      this.editor.signals.historyChanged.dispatch(cmd)
    }
    return cmd
  }
  resetName() {
    const lastCmd = this.undos[this.undos.length - 1]
    if (lastCmd.type === 'AddObjectCommand') {
      const _name = lastCmd.name.split('：')
      lastCmd.name = `${_name[0]}：${lastCmd.object.name}`
    }
  }

  goToState(id) {
    this.editor.signals.sceneGraphChanged.active = false
    this.editor.signals.historyChanged.active = false
    let cmd =
      this.undos.length > 0 ? this.undos[this.undos.length - 1] : undefined
    if (cmd === undefined || id > cmd.id) {
      cmd = this.redo()
      while (cmd !== undefined && id > cmd.id) {
        cmd = this.redo()
      }
    } else {
      while (true) {
        cmd = this.undos[this.undos.length - 1]
        if (cmd === undefined || id === cmd.id) break
        this.undo()
      }
    }
    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.historyChanged.active = true

    this.editor.signals.sceneGraphChanged.dispatch()
    this.editor.signals.historyChanged.dispatch(cmd)
  }
  clear() {
    this.undos = []
    this.redos = []
    this.idCounter = 0
    this.editor.signals.historyChanged.dispatch()
  }
  fromJSON(json) {
    if (json === undefined) return

    for (let i = 0; i < json.undos.length; i++) {
      const cmdJSON = json.undos[i]
      const cmd = new Commands[cmdJSON.type](this.editor) // creates a new object of type "json.type"
      cmd.json = cmdJSON
      cmd.id = cmdJSON.id
      cmd.name = cmdJSON.name
      this.undos.push(cmd)
      this.idCounter = cmdJSON.id > this.idCounter ? cmdJSON.id : this.idCounter // set last used idCounter
    }

    for (let i = 0; i < json.redos.length; i++) {
      const cmdJSON = json.redos[i]
      const cmd = new Commands[cmdJSON.type](this.editor) // creates a new object of type "json.type"
      cmd.json = cmdJSON
      cmd.id = cmdJSON.id
      cmd.name = cmdJSON.name
      this.redos.push(cmd)
      this.idCounter = cmdJSON.id > this.idCounter ? cmdJSON.id : this.idCounter // set last used idCounter
    }

    // Select the last executed undo-command
    this.editor.signals.historyChanged.dispatch(
      this.undos[this.undos.length - 1],
    )
  }
}

export { History }
