/**
 * 预览
 */
import * as THREE from 'three'
import signals from 'signals'
import { UIDiv } from './Ui'
import { PreviewTitle } from './Preview.Title'
import { PreviewTitlePhone } from './Preview.Title.Phone'
import { PreviewBottomBtn } from './Preview.Bottom.Btn'
import { PreviewBottomBtnPc } from './Preview.Bottom.Btn.pc'
import { PreviewControl } from './Preview.Control'
import { PreviewViewport } from './Preview.Viewport'
import { AddObjectCommand } from './commands/AddObjectCommand'
import { PreviewAnimation } from './Preview.Animation'
import axios from 'axios'
import { Loader } from './Loader'
import { History as _History } from './History'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { getHttpUrl } from './Oss.Upload.Temp'
import { httpDesGet } from '@/http/service/model/index'
import { OSS_VISIT_URL } from '@/config/index'
import { message } from 'ant-design-vue'
import NProgress from '@/components/loading/index'
import { isMobile } from './libs/common/libs'

// 整个编辑器的相机DEFAULT_CAMERA
let _DEFAULT_CAMERA = new THREE.PerspectiveCamera(
  45,
  window.innerWidth / window.innerHeight,
  0.01,
  1000,
)
_DEFAULT_CAMERA.name = 'Camera'
_DEFAULT_CAMERA.position.set(8.408, 8.036, 15.167)
_DEFAULT_CAMERA.rotation.set(-22.35, 27.14, 10.62)
_DEFAULT_CAMERA.lookAt(new THREE.Vector3())

class Editor {
  constructor(editor, config) {
    const Signal = signals.Signal
    this.editors = null
    if (editor) {
      this.editors = editor
    }
    // 基础配置信息
    if (config) {
      this.config = { ...config }
      this.isDefault = false
    } else {
      this.config = {
        minDistance: 5,
        maxDistance: 50,
      }
      this.isDefault = true
    }
    this.mainDom = null
    this.json = {}
    this.pageTitle = ''
    // 射线
    this.pmremGenerator = null
    // 相机
    this.camera = _DEFAULT_CAMERA.clone()
    this.scene = new THREE.Scene()
    // 选中的对象
    this.selected = null
    // 渲染器
    this.renderer = null
    // 创建一个混合器，所有的动画骨骼名称对应的mesh都会在整个场景中查找
    this.mixer = new THREE.AnimationMixer(this.scene)
    // 编辑器的文件加载器，主要是导入json、对象等
    this.loader = new Loader(this)
    this.animations = []
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.history = new _History(this)
    this.controls = {}
    this.signals = {
      // 预览视图当前选中的对象
      objectSelected: new Signal(),
      // 窗口大小更改
      windowResize: new Signal(),
      // 工具面板操作
      controlModeChage: new Signal(),
      // 创建渲染
      rendererCreated: new Signal(),
      // 渲染器更新
      rendererUpdated: new Signal(),
      // 添加对象
      objectAdded: new Signal(),
      // 重新渲染场景
      sceneGraphChanged: new Signal(),
      // 执行分解动画
      decomposeAnimation: new Signal(),
      // 执行组合动画
      composeAnimation: new Signal(),
      // 执行观察
      watchAnimation: new Signal(),
      // 场景中3d对象改变
      objectChanged: new Signal(),
      // 设置相机的缩放倍数
      setCameraZoom: new Signal(),
      // 重置控制对象
      resetControl: new Signal(),
      // 移除场景中的对象
      objectRemoved: new Signal(),
      // 展示动画列表面板
      showAnimationPanel: new Signal(),
      showPreviewAnimationPanel: new Signal(),
      // 手机端动画展示
      showPhoneAnimationBtn: new Signal(),
      // 历史记录
      historyChanged: new Signal(),
      cameraChanged: new Signal(),
      addSceneLight: new Signal(),
      cameraResetted: new Signal(),
      // 全部重置动画
      composeAnimationAll: new Signal(),
      // 点击知识点
      handleZhishidianBtn: new Signal(),
      // 显示,关闭知识点详情
      handleKnowledgeEveryPosition: new Signal(),
    }
  }
  loadUi() {
    let container = new UIDiv().setClass('main')
    const previewAnimationPanel = new PreviewAnimation(this)
    // container.add(titleDom, controlPanel, footerDom)
    if (isMobile()) {
      const titleDom = new PreviewTitlePhone(this)
      const footerDom = new PreviewBottomBtn(this)
      const controlPanel = new PreviewControl(this)
      container.add(titleDom, controlPanel, footerDom)
    } else {
      const titleDom = new PreviewTitle(this)
      const footerDomPc = new PreviewBottomBtnPc(this)
      container.add(titleDom, footerDomPc)
    }
    return container
  }
  createRenderer() {
    let currentRenderer = null
    currentRenderer = new THREE.WebGLRenderer({
      antialias: true,
    })
    // 定义渲染器的输出编码
    // currentRenderer.outputColorSpace = THREE.SRGBColorSpace
    // 是否使用物理上正确的光照模式
    // currentRenderer.physicallyCorrectLights = true
    currentRenderer.shadowMap.enabled = true
    currentRenderer.toneMapping = THREE.ACESFilmicToneMapping
    currentRenderer.toneMappingExposure = 1.25
    this.signals.rendererCreated.dispatch(currentRenderer)
    // this.signals.rendererUpdated.dispatch()
    return currentRenderer
  }
  // 更改窗口大小
  onWindowResize() {
    this.signals.windowResize.dispatch()
  }
  // 添加对象
  addObject(object, parent, index) {
    let scope = this
    if (parent === undefined) {
      this.scene.add(object)
    } else {
      parent.children.splice(index, 0, object)
      object.parent = parent
    }
    this.signals.objectAdded.dispatch(object)
    this.signals.sceneGraphChanged.dispatch()
    console.log(this.scene)
  }

  // 选中当前模型对象
  select(object) {
    if (this.selected === object) return
    let uuid = null
    if (object !== null) {
      uuid = object.uuid
    }
    this.selected = object

    this.signals.objectSelected.dispatch(object)
  }
  // vue3中协同的时候根据名字选中对应节点
  selectNode(name) {
    if (name === null) {
      this.selected = null
    } else {
      this.scene.traverse((mesh) => {
        if (mesh.name === name) {
          this.selected = mesh
        }
      })
    }
    this.signals.objectSelected.dispatch(this.selected)
  }
  fetchDec() {
    const id = getHttpUrl().id
    return httpDesGet({
      id,
    })
      .then((res) => res)
      .catch((err) => err)
  }
  getSceneJson = () => {
    let output = this.scene.toJSON()
    try {
      output = JSON.stringify(output, null, '\t')
      output = output.replace(/[\n\t]+([\d\.e\-\[\]]+)/g, '$1')
    } catch (error) {
      output = JSON.stringify(output)
    }
    return output
  }
  // 设置刚好能显示
  resetModel(obj) {
    const boxHelper = new THREE.BoxHelper(obj)
    boxHelper.geometry.computeBoundingBox()
    const box = boxHelper.geometry.boundingBox
    const maxDiameter = Math.max(
      box.max.x - box.min.x,
      box.max.y - box.min.y,
      box.max.z - box.min.z,
    )
    const scaleValue = this.camera.position.z / maxDiameter
    obj.scale.set(scaleValue, scaleValue, scaleValue)
  }
  async fromJSON(json) {
    let loader = new THREE.ObjectLoader()
    let camera = await loader.parseAsync(json.camera)
    this.camera.copy(camera)
    this.signals.cameraResetted.dispatch()
    // this.history.fromJSON(json.history)
    this.scripts = json.scripts ? json.scripts : {}
    this.setScene(
      await loader
        .parseAsync(json.scene)
        .then((res) => {
          return res
        })
        .catch((error) => {
          console.log(error)
        }),
    )
  }
  setScene(scene) {
    this.scene.uuid = scene.uuid
    this.scene.name = scene.name

    this.scene.background = scene.background
    this.scene.environment = scene.environment
    this.scene.fog = scene.fog
    this.scene.userData = JSON.parse(JSON.stringify(scene.userData))

    this.signals.sceneGraphChanged.active = false

    while (scene.children.length > 0) {
      this.addObject(scene.children[0])
    }
    this.signals.sceneGraphChanged.active = true
    this.signals.sceneGraphChanged.dispatch()
  }

  // 重新拉取场景数据
  initScene() {
    let loader = new THREE.ObjectLoader()

    if (this.editors.scene) {
      this.camera.copy(this.editors.camera)
      this.signals.cameraResetted.dispatch()
      this.scripts = this.json.scripts ? this.json.scripts : {}
      this.json = this.editors.scene.toJSON()
      this.scene = loader.parse(this.json)
      this.signals.sceneGraphChanged.dispatch()
    }
  }

  async onRender() {
    const _this = this
    this.mainDom = this.loadUi()
    new PreviewViewport(this)
    // 渲染ui
    this.createRenderer()
    if (this.editors === null) {
      // 加载数据，查看
      let res = await this.fetchDec()
      NProgress.start()
      try {
        if (res.data) {
          let { editTextUrl: json, file: serverJson, projectName } = res.data
          document.title = this.pageTitle = projectName + '预览'
          // 上传模型的时候，存储的是glb类型的模型时做的渲染
          if (serverJson) {
            this.serverJson = serverJson
            const _fileName = serverJson.filePath.split('/').pop()
            const ___fileName = _fileName.split('.')
            // 获取到服务器地址
            const _url = OSS_VISIT_URL + serverJson.filePath
            // 获取自定义的json数据
            const { fileMeta } = serverJson
            const initJson = fileMeta ? JSON.parse(fileMeta) : null
            NProgress.inc()

            const gltfLoader = new GLTFLoader()

            const _res = await new Promise(
              (resolve, reject) => {
                return gltfLoader.load(_url, (glb) => {
                  console.log(glb, 'glb')
                  if (glb.scene.children.length > 0) {
                    function loop(obj1, obj2) {
                      obj1.uuid = obj2.uuid
                      obj1.name = obj2.name
                      if (obj1.children) {
                        for (let i = 0; i < obj1.children.length; i++) {
                          const element = obj1.children[i]
                          loop(element, obj2.children[i])
                        }
                      }
                    }
                    _this.json = glb
                    if (glb.scene.children.length > 0) {
                      loop(glb.scene.children[0], initJson)
                    }
                    const sceneResult = glb.scene.children[0]
                    if (sceneResult.name.toString() === ___fileName[0]) {
                      if (sceneResult.children.length > 0) {
                        for (
                          let i = 0;
                          i < sceneResult.children.length;
                          i = 0
                        ) {
                          const element = sceneResult.children[i]
                          element.animations.push(...glb.animations)
                          _this.execute(new AddObjectCommand(_this, element))
                        }
                      }
                    } else {
                      glb.scene.children[0].animations.push(...glb.animations)
                      _this.execute(
                        new AddObjectCommand(_this, glb.scene.children[0]),
                      )
                    }
                  }
                  NProgress.done()
                  return resolve(true)
                })
              },
              (error) => {
                console.warn(error, 'errorerrorerrorerrorerror')
              },
            )
          }
          if (json) {
            // 获取到服务器地址
            const _url = OSS_VISIT_URL + json
            NProgress.inc()
            await axios.get(_url).then((res) => {
              if (res.data.metadata.type) {
                this.loader.handleJSON(res.data)
                this.json = res.data
              } else {
                this.fromJSON(res.data)
                this.json = res.data.scene
              }
              NProgress.done()
            })
          }
        } else {
          // message.error('该模型项目不存在或已被删除')
          this.signals.sceneGraphChanged.dispatch()
          this.signals.addSceneLight.dispatch()
          NProgress.done()
        }
      } catch (error) {
        console.log(error, 123)
        NProgress.done()
      }
    } else {
      // 拿去浏览器数据，预览
      this.initScene()
    }
    document.body.appendChild(this.mainDom.dom)
    // 窗口大小变化监听事件
    window.addEventListener('resize', this.onWindowResize.bind(this))
  }
  execute(cmd, optionalName) {
    this.history.execute(cmd, optionalName)
  }
  undo() {
    this.history.undo()
  }
  redo() {
    this.history.redo()
  }
  // 删除节点
  removeDom() {
    const bodyNode = document.body
    const canvasElement = document.getElementsByClassName('canvas')
    const mainElement = document.getElementsByClassName('main')
    bodyNode.removeChild(canvasElement[0])
    bodyNode.removeChild(mainElement[0])
  }
}

export { Editor }
