import store, { setBreadcrumbInit } from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import dayjs from 'dayjs'
import NProgress from '@/components/loading/index.js'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeLayout',
    component: () => import('../views/HomeLayout/homeLayout.vue'),
    redirect: '/MainView',
    children: [
      {
        path: '/MainView',
        name: 'MainView',
        component: () => import('../views/Main/MainView.vue'),
        redirect: '/home',
        meta: {
          title: '首页',
        },
        children: [
          {
            path: '/home',
            name: 'home',
            component: () => import('../views/Home/HomeView.vue'),
            meta: {
              title: '首页',
            },
          },
          {
            path: '/ResourceView',
            name: 'ResourceView',
            component: () => import('../views/Resource/ResourceView.vue'),
            meta: {
              title: '资源商店',
            },
          },
          {
            path: '/ResourceDesView',
            name: 'ResourceDesView',
            component: () => import('../views/Resource/ResourceDesView.vue'),
            meta: {
              title: '资源详情',
            },
          },
        ],
      },
      {
        path: '/LayoutView',
        name: 'LayoutView',
        component: () => import('../views/Layout/LayoutView.vue'),
        redirect: '/ProjectView',
        meta: {
          isTarget: true,
        },
        children: [
          {
            path: '/ProjectView',
            name: 'ProjectView',
            component: () => import('../views/Project/ProjectView.vue'),
            meta: {
              title: '我的模型',
            },
          },
          {
            path: '/DownloadView',
            name: 'DownloadView',
            component: () => import('../views/Project/ProjectView.vue'),
            meta: {
              title: '收藏模型',
            },
          },
          {
            path: '/ReleaseResourceView',
            name: 'ReleaseResourceView',
            component: () => import('../views/Project/ReleaseResourceView.vue'),
            meta: {
              title: '发布资源',
            },
          },
          {
            path: '/EditReleaseResourceView',
            name: 'EditReleaseResourceView',
            component: () => import('../views/Project/ReleaseResourceView.vue'),
            meta: {
              title: '编辑资源',
            },
          },
          {
            path: '/RetrieveView',
            name: 'RetrieveView',
            component: () => import('../views/Retrieve/RetrieveView.vue'),
            meta: {
              title: '回收站',
            },
          },
          {
            path: '/PersonalView',
            name: 'PersonalView',
            component: () => import('../views/Personal/PersonalView.vue'),
            meta: {
              title: '个人中心',
            },
          },
          {
            path: '/MyResourceView',
            name: 'MyResourceView',
            component: () => import('../views/Project/MyResourceView.vue'),
            meta: {
              title: '发布模型',
            },
          },
          {
            path: '/MailView',
            name: 'MailView',
            component: () => import('../views/Mail/MailView.vue'),
            meta: {
              title: '站内信',
            },
          },
          {
            path: '/CooperateView',
            name: 'CooperateView',
            component: () => import('../views/Cooperate/CooperateView.vue'),
            meta: {
              title: '协同空间',
            },
          },
          {
            path: '/CourseView',
            name: 'CourseView',
            component: () => import('../views/Course/CourseView.vue'),
            meta: {
              title: '课程管理',
            },
          },
          {
            path: '/EditCourseView',
            name: 'EditCourseView',
            component: () => import('../views/Course/EditCourseView.vue'),
            meta: {
              title: '新建课程',
            },
          },
          {
            path: '/EditCourse',
            name: 'EditCourse',
            component: () => import('../views/Course/EditCourse.vue'),
            meta: {
              title: '编辑课程',
            },
          },
          {
            path: '/MaterialView',
            name: 'MaterialView',
            component: () => import('../views/Material/MaterialView.vue'),
            meta: {
              title: '课件管理',
            },
          },
          {
            path: '/UserView',
            name: 'UserView',
            component: () => import('../views/User/UserView.vue'),
            meta: {
              title: '用户管理',
            },
          },
          // {
          //   path: '/ExamineManger',
          //   name: 'ExamineManger',
          //   component: () => import('../views/Examine/ExamineManger.vue'),
          //   meta: {
          //     title: '考核组件',
          //   },
          // },
          {
            path: '/ExamineView',
            name: 'ExamineView',
            component: () => import('../views/Examine/ExamineView.vue'),
            meta: {
              title: '考核管理',
            },
          },

          {
            path: '/AddExamineView',
            name: 'AddExamineView',
            component: () => import('../views/Examine/AddExamineView.vue'),
            meta: {
              title: '新建考核',
            },
          },
          {
            path: '/EidtExamineView',
            name: 'EidtExamineView',
            component: () => import('../views/Examine/EidtExamineView.vue'),
            meta: {
              title: '编辑考核',
            },
          },
          {
            path: '/LookExamineView',
            name: 'LookExamineView',
            component: () => import('../views/Examine/EidtExamineView.vue'),
            meta: {
              title: '查看考核',
            },
          },
        ],
      },
      {
        path: '/CooperateRoomView',
        name: 'CooperateRoomView',
        component: () => import('../views/Cooperate/CooperateRoomView.vue'),
        meta: {
          title: '协同空间',
        },
      },
      {
        path: '/EncEditView',
        name: 'EncEditView',
        component: () => import('../views/Course/EncEditView.vue'),
        meta: {
          title: '百科编辑器',
        },
      },
      {
        path: '/PreviewView',
        name: 'PreviewView',
        component: () => import('../views/Editor/PreviewView.vue'),
        meta: {
          title: '查看',
        },
      },
      {
        path: '/PreviewViewPhone',
        name: 'PreviewViewPhone',
        component: () => import('../views/Editor/PreviewViewPhone.vue'),
        meta: {
          title: '手机查看',
        },
      },
      {
        path: '/PreviewEditorView',
        name: 'PreviewEditorView',
        component: () => import('../views/Editor/PreviewView.vue'),
        meta: {
          title: '预览',
        },
      },
      {
        path: '/PreviewViewCourse',
        name: 'PreviewViewCourse',
        component: () => import('../views/Editor/PreviewViewCourse.vue'),
        meta: {
          title: '课件查看',
        },
      },
      {
        path: '/invite',
        name: 'invite',
        component: () => import('../views/Layout/InviteView.vue'),
        meta: {
          title: '邀请',
        },
      },
      {
        path: '/EdtorIframe',
        name: 'EdtorIframe',
        component: () => import('../views/EdtorIframe/editIframe.vue'),
        meta: {
          title: '3d',
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior: () => ({ left: 0, top: 0 }),
  // scrollBehavior(to, from, savePosition) {
  //   console.log(11, savePosition)
  //   return new Promise((resolve, reject) => {
  //     if (savePosition) {
  //       // 浏览器前进后退按钮切换路由
  //       resolve(savePosition)
  //     }
  //     resolve({ left: 0, top: 0 }) // // 通过<router-link>切换
  //   })
  // },
})

const setBreadcrumb = (to: any) => {
  let _list: string | any[] = []
  if (store.state.breadcrumb && store.state.breadcrumb.length) {
    _list = [...store.state.breadcrumb]
    for (let i = 0; i < _list.length; i++) {
      const element = _list[i]
      if (element.name === 'ResourceDesView') {
        store.state.breadcrumb.splice(
          i,
          store.state.breadcrumb.length - i,
          ...setBreadcrumbInit([to]),
        )
      } else if (i === _list.length - 1) {
        store.state.breadcrumb.push(...setBreadcrumbInit([to]))
      }
    }
    store.commit('setBreadcrumb', store.state.breadcrumb)
  }
}

/**
 * 解决token-24小时过期之后进入某些可带可不带token的页面弹出token过期提示的需求
 * @returns {boolean} - true:未过期 false:过期
 */

const checkExpireTime = () => {
  const _loginTime = localStorage.getItem('loginTime')
    ? Number(localStorage.getItem('loginTime'))
    : 0
  const _nowTime = dayjs().unix()
  const _isExpire = _nowTime - _loginTime <= 24 * 60 * 60
  return _isExpire
}

router.beforeEach((to, from, next) => {
  NProgress && NProgress.done()
  document.title = (to.meta.title as string) || '科华天府'
  if (!checkExpireTime()) {
    store.commit('setToken')
  }
  if (to.name === 'ResourceDesView') {
    setBreadcrumb(to)
  }
  next()
})

export { routes }
// router.beforeEach((to, from, next) => {
//   if (to.matched && to.matched.length > 2) {
//     to.matched.splice(1, to.matched.length - 2)
//   }
//   next()
// })
export default router
