import axios from 'axios'
import type { AxiosInstance } from 'axios'
import type { HMRequestInterceptors, HMRequestConfig } from './type'
import store from '@/store/index'
import { message, Modal } from 'ant-design-vue'
import { EErrorCodeConfig, EErrorCodeConfigValue } from './type'

const ISLOADING = true
const ISSHOWMESSAGE = true

message.config({
  maxCount: 1,
})

class HMRequest {
  instance: AxiosInstance
  interceptors?: HMRequestInterceptors
  showLoading?: boolean
  showMsg?: boolean
  isToken?: boolean
  isSpecial?: boolean
  isShowError?: boolean

  constructor(config: HMRequestConfig) {
    this.instance = axios.create(config)
    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? ISLOADING
    this.showMsg = config.showMsg ?? ISSHOWMESSAGE
    this.isShowError = config.isShowError ?? true

    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch,
    )

    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch,
    )

    this.instance.interceptors.request.use(
      (config) => {
        store.dispatch('setShowLoading', this.showLoading)
        return config
      },
      (err) => {
        store.dispatch('setShowLoading', false)
        return err
      },
    )

    this.instance.interceptors.response.use(
      (res) => {
        store.dispatch('setShowLoading', false)
        const data = res.data
        if (data.success) {
          return Promise.resolve(data)
        } else {
          // 不做全局提示
          if (this.isShowError) {
            if (data.data) {
              const errors = data.data
              let message = ''
              let index = 1
              for (const prop in errors) {
                message += `${index++}. ${errors[prop]}\n`
                Modal.error({
                  title: '警告',
                  content: message,
                  okText: '关闭',
                })
              }
            } else {
              const getMsg = (data: {
                code: EErrorCodeConfig
                message: string
              }) => {
                let _str = ''
                const _arr = Object.entries(EErrorCodeConfig).find(
                  ([key, value]) => value === data.code,
                )
                if (_arr?.length) {
                  const _arrValue = Object.entries(EErrorCodeConfigValue).find(
                    ([key, value]) => key === _arr[0],
                  )
                  if (_arrValue?.length) {
                    _str = _arrValue[1]
                  }
                }
                if (data.message) {
                  _str = data.message
                }
                return _str
              }
              message.error(data.code ? getMsg(data) : '请联系管理员')
            }
          }
          // as keyof typeof EErrorCodeConfig
          return Promise.reject(data)
        }
      },
      (err) => {
        store.dispatch('setShowLoading', false)
        const resError = err.response
        if (err.response) {
          switch (resError.status) {
            case 401:
              store.dispatch('goHome')
              if (this.showMsg) {
                message.error('登录失效，请重新登录')
              }
              break
            case 403:
              store.dispatch('goHome')
              if (this.showMsg) {
                message.error('你没有权限访问该页面')
              }
              break
            case 500:
              if (this.showMsg) {
                message.error('请联系管理员')
              }
              break
            case 503:
              if (this.showMsg) {
                message.error('请联系管理员')
              }
              break
            default:
              if (this.showMsg) {
                message.error('请联系管理员')
              }
              break
          }
          return Promise.reject(resError.data)
        } else {
          if (this.showMsg) {
            message.error(err.message)
          }
        }
        return Promise.reject(err)
      },
    )
  }
  request<T = any>(config: HMRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }
      if (config.showLoading === false) {
        this.showLoading = false
      }
      if (config.showMsg === false) {
        this.showMsg = false
      }
      if (config.isShowError === false) {
        this.isShowError = false
      }
      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors?.responseInterceptor(res)
          }
          this.showLoading = ISLOADING
          resolve(res)
        })
        .catch((err) => {
          this.showLoading = ISLOADING
          reject(err)
        })
    })
  }
  get<T = any>(config: HMRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'get' })
  }
  post<T = any>(config: HMRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'post' })
  }
  put<T = any>(config: HMRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'put' })
  }
  delete<T = any>(config: HMRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'delete' })
  }
  patch<T = any>(config: HMRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'patch' })
  }
}

export default HMRequest
