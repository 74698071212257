/**
 * 动画配置(操作)面板展示
 */

import { shrinkPanelFun } from './libs/common/libs'
import { UIDiv, UIIcon, UIText, UITextEdit, UIIconUnic } from './Ui'
import { UIRow, UIRowItem } from './Ui.three'

function SidebarAnimationControl(editor) {
  let signals = editor.signals
  let mixer = editor.mixer
  let currentObject = null
  let upAction = null
  let upRow = null

  let container = new UIRow()
  // 动画配置面板
  let panelHeader = new UIRowItem().addClass('animation-box')
  let panelHeaderIcon = new UIIcon('opener icon-icon-test8 cursor')
  panelHeaderIcon.onClick((event) => {
    shrinkPanelFun(event.target)
  })
  const panelHeaderCon = new UIDiv().setClass('flex flex-1 flex-j-b')
  let panelHeadeName = new UIText('动画列表').setMarginLeft('5px')
  const editIcon = new UIIconUnic('\u{e621}')
    .addClass('cursor')
    .addClass('edit-icon')

  // 点击-进行动画名字的配置
  editIcon.onClick(function () {
    //
  })

  panelHeaderCon.add(panelHeadeName, editIcon)
  panelHeader.add(panelHeaderIcon, panelHeaderCon)
  container.add(panelHeader)

  // 动画名称列表
  let animationPanelRow = new UIDiv()

  function getIconState(action) {
    return action.isRunning() ? 'icon-zanting' : 'icon-bofang'
  }

  function addAnimation(animation, object) {
    let action = mixer.clipAction(animation, object)
    let container = new UIDiv()
    let name = new UITextEdit(animation.name).setClass('span-txt')
    container.add(name)

    let icon = new UIIcon(getIconState(action)).addClass('cursor')
    icon.onClick(() => {
      if (upAction !== null && upAction !== action) {
        upAction.stop()
        upRow
          .setClass(getIconState(upAction))
          .addClass('iconfont')
          .addClass('cursor')
      }
      action.isRunning() ? action.stop() : action.play()
      icon
        .setClass(getIconState(action))
        .addClass('iconfont')
        .addClass('cursor')
      upAction = action
      upRow = icon
    })
    container.add(icon)
    return container
  }

  function showAnimationPanel(object) {
    let isShowPanel = false
    if (object !== null && object.animations.length) {
      isShowPanel = true
      animationPanelRow.clear()
      let animations = object.animations
      for (const animation of animations) {
        animationPanelRow.add(new addAnimation(animation, object))
      }
      container.add(animationPanelRow)
    }
    if (isShowPanel) {
      currentObject = object
      container.setDisplay('')
    } else {
      currentObject = null
      container.setDisplay('none')
    }
  }

  signals.objectSelected.add((object) => {
    showAnimationPanel(object)
  })

  signals.showPreviewAnimationPanel.add((object) => {
    showAnimationPanel(object)
  })

  signals.objectRemoved.add((object) => {
    if (object !== null && object.animations.length > 0) {
      mixer.uncacheRoot(object)
    }
  })

  return container
}
export { SidebarAnimationControl }
