import NProgress from 'nprogress' // nprogress插件
import 'nprogress/nprogress.css' // nprogress样式
import '@/editor/assets/css/nprogress.css' // nprogress样式
import './style/index.scss'

const loadingImg = require('./img/loading3-unscreen.gif')

class LoadingComponent {
  constructor(options) {
    this.defaultOptions = {
      template: `<div class="loading-box">
                  <div class="img-boxs">
                    <img src="${loadingImg}" />
                  </div>
                  <span role='bar'></span>
                </div>`,
    }
    Object.assign(this, NProgress)
    this.resetConfigure(options)
  }
  resetConfigure(options) {
    const config = { ...this.defaultOptions, ...options }
    this.configure(config)
  }
}

const loadingComponent = new LoadingComponent()

export default loadingComponent
