/**
 * 更改对象在场景中的位置
 */
import { Vector3 } from 'three'
import { Command } from './Command'

class SetPositionCommand extends Command {
  constructor(editor, object, newPosition, optionalOldPosition) {
    super(editor)
    this.type = 'SetPositionCommand'
    this.updatable = true
    this.object = object
    if (object !== undefined) {
      this.name = `改变位置：${object.name}`
    }
    if (object !== undefined && newPosition !== undefined) {
      this.oldPosition = object.position.clone()
      let { x, y, z } = newPosition
      this.newPosition = newPosition.isVector3
        ? newPosition.clone()
        : new Vector3(x, y, z)
    }
    if (optionalOldPosition !== undefined) {
      this.oldPosition = optionalOldPosition.clone()
    }
  }
  execute() {
    this.object.position.copy(this.newPosition)
    this.object.updateMatrixWorld(true)
    this.editor.pastRecordsObj = {
      position: this.oldPosition.clone(),
      rotation: this.object.rotation.clone(),
      scale: this.object.scale.clone(),
    }
    this.editor.currentContorlType = 'position'
    this.editor.signals.objectChanged.dispatch(this.object, this.oldPosition)
  }
  undo() {
    this.object.position.copy(this.oldPosition)
    this.object.updateMatrixWorld(true)
    this.editor.signals.objectChanged.dispatch(this.object)
  }
  update(command) {
    this.newPosition.copy(command.newPosition)
  }
}

export { SetPositionCommand }
