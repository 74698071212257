import { createStore } from 'vuex'
import { httpLogin } from '@/http/service/login/index'
import { httpUnreadMail } from '@/http/service/mail/index'
import router from '@/router/index'
import dayjs from 'dayjs'
import websockets from '@/utils/Websocket'

interface wsData {
  [x: string]: websockets
}
interface State {
  WS: wsData | null
  showLoading: boolean
  userInfo: any
  token: string | null
  unReadCount: number
  isOUt: boolean
  breadcrumb: any[]
  newPersonInfo: any
  WS_ROOM_LIST: any[]
  videoImgUrl: string | null
  isUploadStatus: boolean
  storeUploadList: any[]
  modelType: number
  animationList: any[]
  isBack: boolean
}

export const setBreadcrumbInit = (list: any[]) => {
  let _arr: { name: any; meta: any }[] = []
  if (list && list.length) {
    _arr = list.map((item) => {
      return {
        name: item.name,
        meta: item.meta,
      }
    })
  }
  return _arr
}

export default createStore<State>({
  state: {
    videoImgUrl: sessionStorage.getItem('imgUrl')
      ? sessionStorage.getItem('imgUrl')
      : null,
    showLoading: false,
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo') as string)
      : {},
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    unReadCount: 0, // 站内信未读数
    isOUt: true, // 点登录默认退出
    breadcrumb: sessionStorage.getItem('breadcrumb')
      ? JSON.parse(sessionStorage.getItem('breadcrumb') as any)
      : [], // 面包屑导航
    newPersonInfo: localStorage.getItem('newPersonInfo')
      ? JSON.parse(localStorage.getItem('newPersonInfo') as any)
      : {},
    WS: null,
    WS_ROOM_LIST: sessionStorage.getItem('WS_ROOM_LIST')
      ? JSON.parse(sessionStorage.getItem('WS_ROOM_LIST') as any)
      : [], // 房间列表
    isUploadStatus: false, // 上传列表默认隐藏
    storeUploadList: [], // 上传列表
    modelType: Number(sessionStorage.getItem('modelType')) || 0, // 1: 拆分类型  2: 动画类型
    animationList: [], // 动画列表
    isBack: false,
  },
  getters: {
    // 是否为企业管理员
    isBusinesser: (state) => {
      const _isHave =
        state.userInfo?.role?.includes('ROLE_business_admin') || false
      return _isHave
    },
    // 主画面用户的id
    IsMainScreenUser(state) {
      return state.WS_ROOM_LIST.filter((item) => item.IsMainScreen)
    },
    // 房主id
    IsHostItme(state) {
      return state.WS_ROOM_LIST.filter((item) => item.IsHost)
    },
    getIdItem: (state) => (id: number) => {
      return state.WS_ROOM_LIST.find((item) => item.Id === id)
    },
    upLoadLen: (state) =>
      state.storeUploadList.filter((item) => item.upStatus === 2).length,
  },
  mutations: {
    setAnimationList(state, value) {
      state.animationList = value.map((item: any, index: number) => {
        const _isSelect = index === 0
        return {
          ...item,
          isSelect: _isSelect,
        }
      })
      console.log(state.animationList, '输出最新值')
    },
    setModelType(state, value) {
      state.modelType = Number(value)
      sessionStorage.setItem('modelType', value)
    },
    setRoomList(state, value) {
      state.WS_ROOM_LIST = value
      sessionStorage.setItem('WS_ROOM_LIST', JSON.stringify(state.WS_ROOM_LIST))
    },
    setNewPersonInfo(state, value) {
      state.newPersonInfo = value
      if (value) {
        localStorage.setItem('newPersonInfo', JSON.stringify(value))
      } else {
        localStorage.removeItem('newPersonInfo')
      }
    },
    setBreadcrumb(state, value) {
      if (value) {
        const _arr = setBreadcrumbInit(value)
        state.breadcrumb = _arr
        sessionStorage.setItem('breadcrumb', JSON.stringify(_arr))
      } else {
        sessionStorage.removeItem('breadcrumb')
      }
    },
    setIsOut(state, value) {
      state.isOUt = value
    },
    setShowLoadingVal(state, value) {
      state.showLoading = value
    },
    setUserInfo(state, value) {
      state.videoImgUrl = value
      state.userInfo = value
      if (value) {
        localStorage.setItem('userInfo', JSON.stringify(value))
      } else {
        localStorage.removeItem('userInfo')
      }
    },
    setToken(state, value) {
      state.token = value
      if (value) {
        localStorage.setItem('token', value)
      } else {
        localStorage.removeItem('token')
      }
    },
    setUnReadCount(state, value) {
      state.unReadCount = value
    },
    initWebSocket(state, list) {
      // 创建帧同步通道
      if (state.WS === null) {
        state.WS = {}
        for (let index = 0; index < list.ws.length; index++) {
          const element = list.ws[index]
          state.WS[element.channel] = new websockets(
            element.uri,
            element.channel,
            list.Editor,
          )
        }
      }
    },
    setVideoUrl(state, value) {
      state.videoImgUrl = value
      if (value) {
        sessionStorage.setItem('imgUrl', value)
      } else {
        sessionStorage.removeItem('imgUrl')
      }
    },
    setUploadStatus(state, value) {
      state.isUploadStatus = value
    },
    setStoreUploadList(state, value) {
      state.storeUploadList = value
    },
    setIsBack(state, value) {
      state.isBack = value
    },
  },
  actions: {
    setAvatar({ commit, state }, value) {
      const _user = state.userInfo
      _user['avatarUrl'] = value
      commit('setUserInfo', _user)
    },
    setNickname({ commit, state }, value) {
      const _user = state.userInfo
      _user['nickname'] = value
      commit('setUserInfo', _user)
    },
    setShowLoading({ commit }, value) {
      commit('setShowLoadingVal', value)
    },
    async accountLoginAction({ commit }, payload) {
      let loginResult: any = {}
      try {
        loginResult = await httpLogin(payload)
      } catch (error) {
        return Promise.resolve(false)
      }
      // 解决token-24小时过期之后进入某些可带可不带token的页面弹出token过期提示的需求
      const nowTime = dayjs().unix()
      localStorage.setItem('loginTime', nowTime.toString())
      const { accessToken } = loginResult.data.token
      const _token = `Bearer ${accessToken}`
      commit('setUserInfo', loginResult.data)
      commit('setToken', _token)
      // 这里统一再做一下邀请时的状态触发
      const { signature } = router.currentRoute.value.query
      if (signature) {
        setTimeout(() => {
          router.replace({
            name: 'PersonalView',
            query: {
              ...router.currentRoute.value.query,
            },
          })
        }, 0)
      }
      return Promise.resolve(true)
    },
    goHome({ commit, state }) {
      router
        .replace({
          name: 'home',
        })
        .then(() => {
          if (state.isOUt) {
            commit('setUserInfo')
            commit('setToken')
          }
        })
    },
    async fetchUnReadMail({ commit }) {
      let _res: any = {}
      _res = await httpUnreadMail()
      commit('setUnReadCount', _res.data ? _res.data : 0)
    },
  },
  modules: {},
})
