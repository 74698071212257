/**
 * 预览-渲染底部操作按钮栏
 */
import { UIDiv, UIIcon, UISpan } from './Ui'

/**
 * 模型不描边时，不显示底部操作
 * 点击模型选中，若没有子元素，则显示组合，观察
 * 点击模型选中，如有子元素，无父元素，则显示拆分，观察
 * 点击模型选中，如有子元素，有父元素，则显示拆分，组合，观察
 */

function PreviewBottomBtn(editor) {
  let isActive = false // 是否选中模型
  let selectedObject = null
  let signals = editor.signals
  const container = new UIDiv().setClass('bottom-box noShow')
  // 描述
  const descriptionDom = new UIDiv().setClass('description')
  const desTxt = new UIDiv().setClass('description-txt')
  const desTitle = new UIDiv().setClass('description-title')
  descriptionDom.add(desTitle, desTxt)

  function setContent(object) {
    let baseContent = object?.userData?.base
    if (baseContent && baseContent.description) {
      hasKnowledge = true
      showZsdPanel(baseContent)
    } else {
      hasKnowledge = false
      hideZsdPanel()
    }
  }

  // 伸缩部分
  const otherDom = new UIDiv().setClass('other-bottom-icon')
  otherDom.onClick(function () {
    if (otherDom.hasClass('top')) {
      otherDom.removeClass('top')
      showOtherPanel()
    } else {
      otherDom.addClass('top')
      hideOtherPanel()
    }
  })
  container.add(otherDom)
  // 按钮部分
  const btnDom = new UIDiv().setClass('box-btn')
  const composeBtn = new UISpan().setClass('btn').setTextContent('组合')
  const splitBtn = new UISpan().setClass('btn').setTextContent('拆分')
  const watchBtn = new UISpan().setClass('btn').setTextContent('观察')
  const zsdBtn = new UISpan()
    .setClass('btn hover active')
    .setTextContent('知识点')
  zsdBtn.onClick(function () {
    if (!this.hasClass('hover')) return false
    if (this.hasClass('active')) {
      this.removeClass('active')
      descriptionDom.setDisplay('none')
    } else {
      this.addClass('active')
      descriptionDom.setDisplay('')
    }
  })

  splitBtn.onClick(() => {
    if (splitBtn.hasClass('hover')) {
      signals.decomposeAnimation.dispatch()
    }
  })

  composeBtn.onClick(() => {
    if (composeBtn.hasClass('hover')) {
      signals.composeAnimation.dispatch()
    }
  })

  watchBtn.onClick(() => {
    if (watchBtn.hasClass('hover')) {
      signals.watchAnimation.dispatch()
    }
  })

  btnDom.add(composeBtn, splitBtn, zsdBtn, watchBtn)
  // 横线
  const hrDom = new UIDiv().setClass('hr-div')
  container.add(btnDom, hrDom, descriptionDom)

  // 设置模型在选中状态下按钮对应的显隐状态
  function setShowAndHideState(object) {
    composeBtn.removeClass('hover')
    splitBtn.removeClass('hover')
    watchBtn.removeClass('hover')

    if (object && object.children.length > 0) {
      splitBtn.addClass('hover')
    }
    if (object && !object.parent.isScene) {
      composeBtn.addClass('hover')
      watchBtn.addClass('hover')
    }
  }

  signals.sceneGraphChanged.add(() => {
    if (
      editor.json &&
      editor.json.animations &&
      editor.json.animations.length
    ) {
      container.setDisplay('none')
    } else {
      container.setDisplay()
      setContent(editor.scene)
    }
  })

  const noneDataText1 = '当前未选中模型'
  const noneDataText2 = '当前模型暂无知识点'
  // 隐藏知识点面板
  const hideZsdPanel = () => {
    desTitle.setTextContent('')
    desTxt.setTextContent('')
  }

  let hasKnowledge = false
  const showZsdPanel = (baseContent) => {
    desTitle.setTextContent(baseContent.title ? baseContent.title : '')
    desTxt.setTextContent(
      baseContent.description ? baseContent.description : '',
    )
  }

  const setContentStatus = () => {
    if (isActive) {
      if (!hasKnowledge) {
        showZsdPanel({
          description: noneDataText2,
        })
      }
    } else {
      showZsdPanel({
        description: noneDataText1,
      })
    }
  }

  // 隐藏其他
  const hideOtherPanel = () => {
    btnDom.addClass('noShow')
    hrDom.addClass('noShow')
    descriptionDom.addClass('noShow')
  }
  const showOtherPanel = () => {
    btnDom.removeClass('noShow')
    hrDom.removeClass('noShow')
    descriptionDom.removeClass('noShow')
  }

  signals.objectSelected.add((object) => {
    selectedObject = object
    if (object !== null) {
      isActive = true
      container.removeClass('noShow')
    } else {
      isActive = false
    }

    setContent(object)
    setShowAndHideState(object)
    setContentStatus()
  })

  return container
}
export { PreviewBottomBtn }
