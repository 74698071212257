import axiosRequest from '../../index'
import Api from '../../api'

// 获取模型详情（分享）
export const httpDes = (params: { id: number }) =>
  axiosRequest.get({
    url: Api.three.des,
    params,
  })

export const httpCourseProjectDes = (params: { id: number }) =>
  axiosRequest.get({
    url: Api.three.courseDes,
    params,
  })

// 获取oss——token
export const httpOssToken = () =>
  axiosRequest.get({
    url: Api.project.oss,
    showLoading: false,
  })

// 保存场景数据
export const httpSave = (data: {
  id: number
  editTextUrl: string
  file: any
  exhibitURL?: string
}) =>
  axiosRequest.put({
    url: Api.three.project,
    data,
  })

// 保存fbx
export const httpSaveFbx = (data: { id: number; files: any }) =>
  axiosRequest.put({
    url: Api.three.project,
    data,
  })

// 上传封面
export const httpImgSaves = (data: { id: number; exhibitURL: string }) =>
  axiosRequest.put({
    url: Api.three.project,
    data,
  })

// 获取分享过来的模型详情
export const httpDesGet = (params: { id: number }) =>
  axiosRequest.get({
    url: Api.three.share,
    params,
  })

// 修改节点名字
export const httpMoadlRname = (data: {
  encyclopediaId: number
  nodeName: string
  uuid: string
}) =>
  axiosRequest.put({
    url: Api.modal.update,
    data,
    showLoading: false,
  })
