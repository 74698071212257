/**
 * 预览-加载渲染等
 */
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { UIDiv } from './Ui'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { SetPositionCommand } from './commands/SetPositionCommand'
import TWEEN from 'tween'
import { PreviewAnimation } from './Preview.Animation'
import store from '@/store'
import { useStore } from 'vuex'

function PreviewViewport(editor) {
  const store = useStore()
  let ws = null
  let container = new UIDiv().setClass('canvas')
  let signals = editor.signals
  let mixer = editor.mixer
  // 渲染场景相机
  let camera = editor.camera
  let scene = editor.scene
  let renderer = null
  let pmremGenerator = null

  let outlinePass = null
  let effectFXAA = null
  let composer = null
  let selectedObjects = []

  let objects = []

  let originalCameraPosition = editor.camera.position
    .clone()
    .sub(new THREE.Vector3())
    .length()

  // 对象拾取
  const raycaster = new THREE.Raycaster()
  let mouse = new THREE.Vector2()

  // 添加3d物体对象变换控制器
  let minDistance = editor.config.minDistance
  let maxDistance = editor.config.maxDistance
  let trackballControls = null

  // 模型控制
  function initContorl() {
    // let trackballControls = new OrbitControls(camera, container.dom)
    let trackballControls = new OrbitControls(camera, renderer.domElement)
    trackballControls.panSpeed = 0.8
    trackballControls.rotateSpeed = 2
    trackballControls.zoomSpeed = 2
    trackballControls.minDistance = minDistance
    trackballControls.maxDistance = maxDistance
    // trackballControls.target.set(0, 0.5, 0)
    trackballControls.update()
    trackballControls.enablePan = false
    trackballControls.enableDamping = false
    trackballControls.addEventListener('change', () => {
      let zoom = watchZoom()
      signals.setCameraZoom.dispatch(zoom)
      if (store?.state?.WS) {
        ws = store.state.WS
      }
      if (ws !== null) {
        const position = new THREE.Vector3()
        const v3 = camera.getWorldPosition(position)
        const quaternion = new THREE.Quaternion()
        const v4 = camera.getWorldQuaternion(quaternion)
        ws['rtc'].sendRtc(147, store.state.userInfo.id, v3, v4)
      }
      console.log('改变了,旋转缩放')
    })
    return trackballControls
  }
  // 渲染场景
  function render() {
    renderer.render(editor.scene, editor.camera)
    // renderer.autoClear = false
    // renderer.setViewport(0, 0, editor.width, editor.height)
    // renderer.autoClear = true
  }
  // 相机重置
  function updateAspectRatio() {
    editor.width = window.innerWidth
    editor.height = window.innerHeight
    camera.aspect = editor.width / editor.height
    camera.updateProjectionMatrix()
  }
  // 动画
  let clock = new THREE.Clock()
  function animate() {
    let mixer = editor.mixer
    let detal = clock.getDelta()
    let needsUpdate = false
    if (mixer.stats.actions.inUse > 0) {
      mixer.update(detal)
      needsUpdate = true
    }
    if (trackballControls) trackballControls.update()
    if (composer && outlinePass.selectedObjects.length) {
    }
    composer && composer.render()
    TWEEN.update()
    if (needsUpdate === true) render()
    const cameraPositon = editor.camera.position.clone()
    // editor.scene.children.filter((item) => {
    //   if (item.isLight) {
    //     item.position.set(cameraPositon.x, cameraPositon.y, cameraPositon.z)
    //   }
    // })
  }

  const onDownPosition = new THREE.Vector2()
  const onUpPosition = new THREE.Vector2()

  // 创建合成器用于后期描边
  function createEffectComposer(renderer) {
    const renderTarget = new THREE.WebGLRenderTarget(
      editor.width,
      editor.height,
      {
        encoding: THREE.sRGBEncoding,
      },
    )
    // 创建合成器(以便添加后期处理步骤)
    composer = new EffectComposer(renderer, renderTarget)
    composer.setSize(editor.width, editor.height)
    composer.setPixelRatio(window.devicePixelRatio)
    // 开始把渲染好的场景作为输出提供给下一个后期步骤(在上个对象上添加通道，如果没有通道，场景就不会被渲染)
    const renderPass = new RenderPass(editor.scene, editor.camera)
    composer.addPass(renderPass)
    // // 描线
    outlinePass = new OutlinePass(
      new THREE.Vector2(editor.width, editor.height),
      editor.scene,
      editor.camera,
    )
    outlinePass.renderToScreen = false
    outlinePass.visibleEdgeColor.set(0xf35040) // 描边颜色
    outlinePass.hiddenEdgeColor.set(0xf35040) // 描边颜色
    outlinePass.edgeStrength = 5 // 粗细
    outlinePass.edgeThickness = 2 // 发光强度
    outlinePass.edgeGlow = 1
    // outlinePass.pulsePeriod = 1; // 闪烁频率
    composer.addPass(outlinePass)

    // 抗锯齿
    effectFXAA = new ShaderPass(FXAAShader)
    effectFXAA.uniforms['resolution'].value.set(
      1 / editor.width,
      1 / editor.height,
    )
    composer.addPass(effectFXAA)
    // composer.addPass(new ShaderPass(GammaCorrectionShader));
  }

  function getMousePosition(dom, x, y) {
    const rect = dom.getBoundingClientRect()
    return [(x - rect.left) / rect.width, (y - rect.top) / rect.height]
  }

  // 获取场景半径
  function getSceneRadius() {
    const box = new THREE.Box3()
    box.expandByObject(editor.scene)
    const sphere = box.getBoundingSphere(new THREE.Sphere(new THREE.Vector3()))
    return sphere.radius
  }

  // 获取模型当前中心点位置
  function getPositionCenter(object) {
    const box = new THREE.Box3()
    let centerPosition = new THREE.Vector3()
    box.expandByObject(object)
    return box.getCenter(centerPosition)
  }
  // 重置场景中心点
  function resetSceneCenterPosition(object) {
    const centerPostion = getPositionCenter(object)
    object.position.x = object.position.x - centerPostion.x
    object.position.y = object.position.y - centerPostion.y
    object.position.z = object.position.z - centerPostion.z
  }
  // 重新设置中心点动画
  function resetFocusPosition(object) {
    const centerPosition = getPositionCenter(object)
    let endPostion = {
      x: editor.scene.position.x - centerPosition.x,
      y: editor.scene.position.y - centerPosition.y,
      z: editor.scene.position.z - centerPosition.z,
    }
    new TWEEN.Tween(editor.scene.position)
      .to(endPostion)
      .easing(TWEEN.Easing.Quadratic.Out)
      .start()
  }

  // 拆分动画时隐藏其他模型
  function hideOtherPart(objects) {
    if (!objects.parent) return
    objects.parent.children.forEach((item) => {
      if (!item.isLight) {
        item.visible = false
      }
    })
    objects.visible = true
  }

  // 组合动画时显示其他模型
  function showOtherPart(objects) {
    if (!objects.parent) return
    objects.parent.children.forEach((item) => {
      if (!item.isLight) {
        item.visible = true
      }
    })
    objects.children.forEach((item) => {
      if (!item.isLight) {
        item.visible = true
      }
    })
  }

  // 射线检测
  function getIntersects(point) {
    mouse.set(point.x * 2 - 1, -(point.y * 2) + 1)
    // 通过摄像机和鼠标位置更新射线
    raycaster.setFromCamera(mouse, camera)
    const objects = []
    scene.traverseVisible((child) => {
      objects.push(child)
    })
    // 计算物体和射线的焦点
    let raycasterArray = raycaster.intersectObjects(objects, false)
    let _parentObj = null
    if (raycasterArray.length > 0) {
      // 取射线检测的第一个元素
      for (let i = 0; i < 1; i++) {
        const item = raycasterArray[i].object
        _parentObj = item
        if (item.children.length) {
          _parentObj = item.parent
        }

        while (
          _parentObj.parent &&
          !_parentObj.parent.isScene &&
          !_parentObj.parent.isSplite
        ) {
          _parentObj = _parentObj.parent
        }
        if (!_parentObj) return
        selectedObjects = [_parentObj]
      }
    } else {
      selectedObjects = []
    }
    return selectedObjects
  }

  function handleClick() {
    if (onDownPosition.distanceTo(onUpPosition) === 0) {
      const intersects = getIntersects(onUpPosition)
      if (store?.state?.WS) {
        ws = store.state.WS
      }
      if (intersects.length > 0) {
        const object = intersects[0]
        editor.select(object)
        ws && ws['rti'].sendRti(9, object.name)
      } else {
        editor.select(null)
        ws && ws['rti'].sendRti(9, null)
      }
    }
  }

  function onMouseUp(event) {
    const array = getMousePosition(container.dom, event.clientX, event.clientY)
    onUpPosition.fromArray(array)
    handleClick()
    document.removeEventListener('mouseup', onMouseUp)
  }

  function onMouseDown(event) {
    const array = getMousePosition(container.dom, event.clientX, event.clientY)
    onDownPosition.fromArray(array)
    document.addEventListener('mouseup', onMouseUp)
  }

  // 场景缩放监听
  function watchZoom() {
    let _cameraPosition = editor.camera.position
      .clone()
      .sub(new THREE.Vector3())
      .length()
    let zoom = Math.floor((originalCameraPosition / _cameraPosition) * 100)
    return zoom
  }

  container.dom.addEventListener('mousedown', onMouseDown)

  // 创建场景
  signals.rendererCreated.add((newRenderer) => {
    if (renderer !== null) {
      renderer.setAnimationLoop(null)
      renderer.dispose()
      pmremGenerator.dispose()
    }
    renderer = newRenderer
    renderer.setAnimationLoop(animate)
    renderer.setClearColor(0x333333)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(editor.width, editor.height)
    pmremGenerator = new THREE.PMREMGenerator(renderer)
    pmremGenerator.compileEquirectangularShader()
    container.dom.appendChild(renderer.domElement)
    render()
    trackballControls = initContorl()
  })

  // 更新场景
  signals.rendererUpdated.add(() => {
    render()
  })

  // 窗口更改重新渲染操作视图
  signals.windowResize.add(() => {
    // const width = editor.width || window.innerWidth
    // const height = editor.height || window.innerHeight
    updateAspectRatio()
    renderer.setSize(editor.width, editor.height)
    composer && composer.setSize(editor.width, editor.height)
    effectFXAA &&
      effectFXAA.uniforms['resolution'].value.set(
        1 / editor.width,
        1 / editor.height,
      )
    // trackballControls.handleResize()
    render()
  })

  // 添加对象
  signals.objectAdded.add((object) => {
    object.traverse((child) => {
      objects.push(child)
    })
  })

  // 判断是否有权限做数据操作
  const checkLimite = () => {
    const nowLoginId = store.state.newPersonInfo.id
    const nowItem = store.getters.getIdItem(nowLoginId)
    return nowItem.IsControl
  }
  // 当前选中对象进行描边
  signals.objectSelected.add((object) => {
    if (outlinePass && object !== null && !object.isLight) {
      // 描边
      console.log(outlinePass)
      outlinePass.selectedObjects = [object]
      resetFocusPosition(object)
    } else if (outlinePass) {
      outlinePass.selectedObjects = []
    }
  })

  // 重新渲染场景
  // let isCheckGroup = false // 是否是组包裹的
  signals.sceneGraphChanged.add(() => {
    scene = editor.scene
    if (composer === null) {
      createEffectComposer(renderer)
    }
    for (let index = 0; index < scene.children.length; index++) {
      if (scene.children[index].isGroup) {
        // isCheckGroup = true
        if (scene.children[index].position.y !== 0) {
          resetSceneCenterPosition(editor.scene)
        }
      }
    }
    // if (!isCheckGroup) {
    //   resetSceneCenterPosition(editor.scene)
    // }
    if (editor.isDefault) {
      trackballControls.minDistance = originalCameraPosition / 2
      trackballControls.maxDistance = originalCameraPosition / 0.25
    }
    // 如果是带骨骼动画的模型则直接展示动画列表面板
    if (editor.json.animations && editor.json.animations.length) {
      signals.showAnimationPanel.dispatch()
    }
    // 先执行一次，解决视角不对的问题
    signals.windowResize.dispatch()
  })

  // 创建多个补间动画
  function createTweenChain(obj, targets, objects) {
    const tweens = []
    for (let i = 0; i < targets.length; i++) {
      let target = targets[i]
      let duration = 1000
      let tween = new TWEEN.Tween(obj)
        .easing(TWEEN.Easing.Quadratic.Out)
        .to(target)
        .onUpdate(() => {
          new SetPositionCommand(editor, objects, target).execute()
          console.log('改变')
        })
      obj = target
      tweens.push(tween)
    }
    for (let j = 0; j < tweens.length; j++) {
      let currentTween = tweens[j]
      let nextTween = tweens[j + 1]
      currentTween.chain(nextTween)
    }
    return tweens[0]
  }

  let _upNode = null
  // 拆分动画
  signals.decomposeAnimation.add(() => {
    //选中的对象
    let _objects = editor.selected.children
    _upNode = editor.selected
    editor.selected.isSplite = true
    if (_objects.length) {
      hideOtherPart(editor.selected)
      for (let i = 0; i < _objects.length; i++) {
        if (
          !_objects[i].isLight &&
          _objects[i].userData.splitAnimationposition
        ) {
          let _startPosition = {
            ..._objects[i].userData.splitAnimationposition[0],
          }

          const positionList = [..._objects[i].userData.splitAnimationposition]
          // let chainArr = []
          // for (let index = 0; index < positionList.length - 1; index++) {
          //   let element = positionList[0]
          //   let aa = new TWEEN.Tween(element)
          //     .to(positionList[index + 1], 1000)
          //     .easing(TWEEN.Easing.Linear.None)
          //     .onUpdate(() => {
          //       new SetPositionCommand(editor, _objects[i], element).execute()
          //     })
          //     .onComplete(() => {
          //       chainArr[index + 1]?.start()
          //     })
          //   chainArr.push(aa)
          // }

          // chainArr[0]?.start()

          new TWEEN.Tween(_startPosition)
            .to(_objects[i].userData.splitAnimationposition[1])
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
              new SetPositionCommand(
                editor,
                _objects[i],
                _startPosition,
              ).execute()
            })
            .start()
        }
      }
    }
    editor.select(null)
  })

  // 回归顶层的组合动画
  signals.composeAnimationAll.add(() => {
    let _objects = _upNode
    let maxBigObject
    while (_objects) {
      _objects.isSplite = false
      if (_objects.children.length) {
        showOtherPart(_objects)
        let _children = _objects.children
        for (let i = 0; i < _children.length; i++) {
          if (
            !_children[i].isLight &&
            _children[i].userData.splitAnimationposition
          ) {
            let _startPosition = {
              ..._children[i].userData.splitAnimationposition[1],
            }
            new TWEEN.Tween(_startPosition)
              .to(_children[i].userData.splitAnimationposition[0])
              .easing(TWEEN.Easing.Quadratic.Out)
              .onUpdate(() => {
                new SetPositionCommand(
                  editor,
                  _children[i],
                  _startPosition,
                ).execute()
              })
              .onComplete(() => {
                editor.select(maxBigObject)
              })
              .start()
          }
        }
        // editor.select(_objects)
        if (_objects.parent.isScene) {
          maxBigObject = _objects
          _objects = null
        } else {
          _objects = _objects.parent
        }
      }
    }
    _upNode = null
  })

  // 组合动画
  signals.composeAnimation.add(() => {
    let _objects = editor.selected?.parent
    editor.selected.parent.isSplite = false
    if (_objects.children.length) {
      showOtherPart(_objects)
      let _children = _objects.children
      for (let i = 0; i < _children.length; i++) {
        if (
          !_children[i].isLight &&
          _children[i].userData.splitAnimationposition
        ) {
          let _startPosition = {
            ..._children[i].userData.splitAnimationposition[1],
          }

          const positionList = [..._children[i].userData.splitAnimationposition]
          // let chainArr = []
          // let j = 0
          // for (let index = positionList.length - 1; index > 0; index--) {
          //   let element = positionList[index]
          //   let aa = new TWEEN.Tween(element)
          //     .to(positionList[index - 1], 1000)
          //     .easing(TWEEN.Easing.Linear.None)
          //     .onUpdate(() => {
          //       new SetPositionCommand(editor, _children[i], element).execute()
          //     })
          //     .onComplete(() => {
          //       console.log(j, '123')
          //       chainArr[j]?.start()
          //       j++
          //     })
          //   chainArr.push(aa)
          // }

          // chainArr[0]?.start()

          new TWEEN.Tween(_startPosition)
            .to(_children[i].userData.splitAnimationposition[0])
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
              new SetPositionCommand(
                editor,
                _children[i],
                _startPosition,
              ).execute()
            })
            .onComplete(() => {
              editor.select(_objects)
            })
            .start()
        } else {
          editor.select(_objects)
        }
      }
    }
  })

  // 观察动画
  signals.watchAnimation.add(() => {
    editor.selected && hideOtherPart(editor.selected)
    outlinePass.selectedObjects = []
    editor.select(null)
  })

  // 重置控制对象
  signals.resetControl.add(() => {
    // trackballControls.reset()
    // resetSceneCenterPosition(editor.scene)
    signals.composeAnimationAll.dispatch()
  })

  signals.cameraChanged.add(() => {
    console.log('改变了相机')
    if (trackballControls) trackballControls.update()
  })

  document.body.appendChild(container.dom)
}
export { PreviewViewport }
