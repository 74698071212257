import TWEEN from 'tween'
import { Command } from './Command'

class SetObjectPanAnimationCommand extends Command {
  constructor(editor, step) {
    super(editor)
    this.type = 'SetObjectPanAnimationCommand'
    this.object = editor.scene
    if (this.object !== undefined) {
      this.name = `动画设置：${this.object.name}`
    }
    this.stepX = editor.scene.position.x
    this.stepY = editor.scene.position.y
    this.timer = 0
    this.step = step === undefined ? 1 : step
  }
  // 开始循环
  startLoopFun(callback) {
    this.timer = setTimeout(() => {
      callback && callback()
      this.startLoopFun(callback)
    }, 300)
  }

  // 停止循环
  clear() {
    if (this.timer) {
      clearTimeout(this.timer)
      TWEEN.removeAll()
    }
  }

  left() {
    this.startLoopFun(() => {
      this.stepX -= this.step
      new TWEEN.Tween(this.object.position)
        .to({ x: this.stepX })
        .easing(TWEEN.Easing.Quadratic.Out)
        .start()
    })
  }

  right() {
    this.startLoopFun(() => {
      this.stepX += this.step
      new TWEEN.Tween(this.object.position)
        .to({ x: this.stepX })
        .easing(TWEEN.Easing.Quadratic.Out)
        .start()
    })
  }

  top() {
    this.startLoopFun(() => {
      this.stepY += this.step
      new TWEEN.Tween(this.object.position)
        .to({ y: this.stepY })
        .easing(TWEEN.Easing.Quadratic.Out)
        .start()
    })
  }

  bottom() {
    this.startLoopFun(() => {
      this.stepY -= this.step
      new TWEEN.Tween(this.object.position)
        .to({ y: this.stepY })
        .easing(TWEEN.Easing.Quadratic.Out)
        .start()
    })
  }

  reset() {
    this.editor.signals.resetControl.dispatch()
  }
  execute(type) {
    this.object = this.editor.scene
    this[type]()
  }
}

export { SetObjectPanAnimationCommand }
