/**
 * 删除场景中的模型对象
 */
import { Command } from "./Command";

class RemoveObjectCommand extends Command {
  constructor(editor, object) {
    super(editor);
    this.type = "RemoveObjectCommand";
    this.object = object;
    if (object !== undefined) {
      this.name = `移除对象：${object.name}`;
    }
    this.parent = object !== undefined ? object.parent : undefined;
    if (this.parent !== undefined && this.parent !== null) {
      this.index = this.parent.children.indexOf(this.object);
    }
  }
  execute() {
    this.editor.removeObject(this.object);
    this.editor.deselect();
  }
  undo() {
    this.editor.addObject(this.object, this.parent, this.index);
    this.editor.select(this.object);
  }
}

export { RemoveObjectCommand };
