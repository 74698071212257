export default {
  user: {
    login: '/user/login', // 登录
    captcha: '/user/captcha', // 验证码
    registerCaptcha: '/user/register/captcha', // 注册验证码
    forgetCaptcha: '/user/forget/captcha', // 忘记密码验证码
    register: '/user/register', // 注册
    forgetPwd: '/user/forget/password', // 忘记密码
    goout: '/personal/logout', // 退出登录
    putPwd: '/user/profile/password', // 修改密码
    createLink: '/user/admin/org/invite_link', // 生成邀请链接
    join: '/user/org/join', // 加入
    exit: '/user/org/quit', // 退出组织
    list: '/user/admin/account/list', // 用户列表
  },
  project: {
    list: '/content/project/list', // 列表
    project: '/content/project',
    oss: '/content/oss/sts', // 获取oss-config
    uploadBack: '/content/project/file_callback', // 模型附件上传回调
    delete: '/content/project/file', // 删除附件
    publish: '/content/project/publish', // 发布资源
    updateStatus: '/content/project/file/status', // 更新模型文件状态
    isUseEnc: '/content/project/in_use', // 判断模型是否在百科中被使用
    copyProject: '/content/project/duplicate', // 复制模型
  },
  retrieve: {
    list: '/content/dumpster/list', // 回收站列表
    retrieve: '/content/dumpster', // 回收站-模型恢复、删除
  },
  personal: {
    des: '/user/profile',
    captcha: '/content/profile/captcha', // 验证码
    captchaNew: '/user/profile/new_phone_captcha', // 验证码-新手机
    captchaOld: '/user/profile/old_phone_captcha', // 验证码-旧手机
    checkOldPhone: '/user/profile/check_old_phone', // 旧手机号验证
    checkNewPhone: '/user/profile/change_new_phone', // 更换新手机
  },
  mail: {
    des: '/content/userMail', // 站内信消息详情，删除
    list: '/content/userMail/list', // 站内信消息列表
    unread: '/content/userMail/list_unread', // 站内信未读消息数
  },
  resourse: {
    list: '/content/resource/list', // 资源列表
    des: '/content/resource/details', // 资源详情
    delete: '/content/resource', // 删除
    down: '/content/resource/download', // 资源下载
    praise: '/content/resource/praise', // 资源点赞
    desSource: '/content/project/resource', // 根据模型id查询资源详情
    mySource: '/content/resource/list_user', // 个人的资源列表
  },
  three: {
    des: '/content/project', // 获取分详情页数据
    share: '/content/project/share', // 获取分享出来的详情页数据
    project: '/content/project', // 获取模型模型详情, 保存场景数据
    courseDes: '/content/encyclopedia/project', // 获取课程下模型模型详情
  },
  cooperate: {
    create: '/rtc/v2/room', // 创建协同空间
    list: '/rtc/v2/rooms', // 协同空间列表
    join: '/rtc/v2/login', // 加入房间
    login: '/user/login/password ', // 登录
  },
  course: {
    list: '/content/course/list', // 获取课程列表
    course: '/content/course', // 创建 修改 删除
    category: '/content/course/category/list', // 获取课程分类
    encList: '/content/encyclopedia/list', // 获取百科列表
    enc: '/content/encyclopedia', // 创建 修改 删除 查看百科
    encSort: '/content/encyclopedia/sort', // 修改百科顺序
    point: '/content/knowledge_point', // 创建 修改 删除 查看 知识点
    pointSort: '/content/knowledge_point/sort', // 知识点排序
    resourceList: '/content/courseware/resource/list', // 课件 资源库列表
    batchPoint: '/content/knowledge_point/batch', // 批量添加超链接
    pointList: '/content/knowledge_point/list', // 获取知识点
    resourceUpload: '/content/courseware/resource', // 资源上传
    blankEnc: '/content/encyclopedia/blank', // 创建空白百科
    modelEnc: '/content/encyclopedia/model', // 创建模型百科
    linkEnc: '/content/encyclopedia/link', // 创建链接百科
    projectEnc: '/content/encyclopedia/project', // 创建模型型百科
    abPack: '/content/course/ab_pack/status', // 修改课程同步状态
    materialList: '/content/courseware/repository', // 资源库列表
    delMateria: '/content/courseware/resource', // 删除资源
    metaList: '/content/meta/teach_category/list', // 获取设备分类
    tagList: '/content/meta/teach_tag/list', // 获取课程标签
    multiMedia: '/content/encyclopedia/multi_media', // 创建图片、音视频、文档百科
    delAb: '/content/encyclopedia/ab', // 删除百科ab包
    encPedia: '/content/course/pedia', // 创建课程
    offline: '/content/course/offline', // 下线课程
    online: '/content/course/online', // 上线课程
  },
  modal: {
    update: '/content/model_node', // 修改节点名字
  },
  examine: {
    courseTypeList: '/content/meta/teach_category/list', // 获取课程分类列表
    courseTagList: '/content/meta/teach_tag/list', // 获取课程标签列表
    list: '/content/examine/list', // 获取考核列表
    create: '/content/examine', // 创建考核
    exercise: '/content/encyclopedia/exercise/normal', // 创建习题百科
    exerciseOperation: '/content/encyclopedia/exercise/operation', // 创建操作习题
    sort: '/content/encyclopedia/sort', // 百科排序
    delete: '/content/course/pedia', // 删除百科
    update: '/content/encyclopedia', // 修改百科
  },
  admin: {
    userInfo: '/user/admin/account/profile', // 管理员获取用户个人信息
    updateInfo: '/user/admin/account/name', // 修改用户真实姓名
    removeOrg: '/user/admin/account/org/quit', // 移出团队
  },
}
