import { Command } from "./Command";

class SetUuidCommand extends Command {
  constructor(editor, object, newUuid) {
    super(editor);
    this.type = "SetUuidCommand";
    this.object = object;
    if (object !== undefined) {
      this.name = `设置id：${object.name}`;
    }
    this.oldUuid = object !== undefined ? object.uuid : undefined;
    this.newUuid = newUuid;
  }
  execute() {
    this.object.uuid = this.newUuid;
    this.editor.signals.objectChanged.dispatch(this.object);
    this.editor.signals.sceneGraphChanged.dispatch();
  }
  undo() {
    this.object.uuid = this.oldUuid;
    this.editor.signals.objectChanged.dispatch(this.object);
    this.editor.signals.sceneGraphChanged.dispatch();
  }
  update(command) {
    this.newPosition.copy(command.newPosition);
  }
}

export { SetUuidCommand };
