/**
 * rtm 房间通道
 * rti 互动消息通道
 * rtc 帧同步通道
 * rta 音频通道
 * rtv 视频通道
 */
import store from '@/store/index'
import { HMconfirm } from '@/utils/common'
import { message } from 'ant-design-vue'
import * as THREE from 'three'
import { Quaternion, Vector3, Vector4 } from 'three'
// import * as Zlib from '@/hooks/gunzip.min.js'
const Zlib = require('@/hooks/gunzip.min.js')
message.config({
  maxCount: 1,
})

// 音频
let dataID_aud = 0
let receivedLength_aud = 0
let dataByte_aud = new Uint8Array(100)
let ReadyToGetFrame_aud = true
const audioCtx = new AudioContext()

let dataId = 0

// 视屏
let dataID_img = 0
let receivedLength_img = 0
let dataByte_img = new Uint8Array(0)
let ReadyToGetFrame_img = true
class WebSocketInstance {
  url: any
  websocket: WebSocket | null
  heartbeatTimer: number | null
  time: number
  name: string
  versionRti: number
  Editor: any
  rimContent: any | null
  rtmContent: any | null
  constructor(url: any, name: string, Editor: any, time = 5000) {
    this.url = url
    this.name = name
    this.websocket = null
    this.time = time
    this.heartbeatTimer = null
    this.versionRti = 0
    this.Editor = Editor
    this.rimContent = null
    this.rtmContent = null
    this.initWebSocket()
  }
  getTxt(str: string) {
    const utf8Bytes = new TextEncoder().encode(str) // 使用 TextEncoder 将 Unicode 编码字符串转换为 UTF-8 编码的字节数组
    const utf8Str = new TextDecoder('utf-8').decode(utf8Bytes) // 使用 TextDecoder 将 UTF-8 编码的字节数组转换为字符串
    console.log(utf8Str) // 输出："你好"
    return utf8Str
  }
  setByte(num: number) {
    const buffer = new ArrayBuffer(4) // 创建一个 4 字节大小的 ArrayBuffer 对象
    const dataView = new DataView(buffer) // 创建一个 DataView 对象
    dataView.setUint32(0, num, true) // 将前端数值写入 DataView 对象
    const bytes = new Uint8Array(buffer) // 将 ArrayBuffer 对象转换为 Uint8Array 数组
    return bytes
  }
  setEncoderByte(msg: string) {
    const encoder = new TextEncoder()
    const encodedData = encoder.encode(msg)
    return encodedData
  }
  CombineInt8Array(a: any, b: any) {
    const c = new Int8Array(a.length + b.length)
    c.set(a)
    c.set(b, a.length)
    return c
  }
  ByteToInt32(_byte: Uint8Array | number[], _offset: number) {
    return (
      (_byte[_offset] & 255) +
      ((_byte[_offset + 1] & 255) << 8) +
      ((_byte[_offset + 2] & 255) << 16) +
      ((_byte[_offset + 3] & 255) << 24)
    )
  }
  getByte(
    val: Blob,
    callback: ((arg0: string | ArrayBuffer) => any) | undefined,
  ) {
    return new Promise((reslove) => {
      const reader = new FileReader()
      let bytes = {}
      reader.readAsArrayBuffer(val)
      reader.onload = () => {
        const result = reader.result as string | ArrayBuffer
        bytes = callback && callback(result)
        reslove(bytes)
      }
    })
  }
  getCode(start: number, end: number, arrayBuffer: Uint8Array) {
    const _arrayBuffer = arrayBuffer.slice(start, end)
    const dataView = new DataView(_arrayBuffer.buffer) // 创建 DataView 对象
    const code = dataView.getInt32(0, true)
    return code
  }
  findChangedProperties(newValue: any, oldValue: any) {
    const changedProperties: any = []
    if (newValue && oldValue) {
      for (const key in newValue) {
        if (newValue[key] !== oldValue[key]) {
          changedProperties.push({
            key,
            value: newValue[key],
          })
        }
      }
    }
    return changedProperties
  }

  // 创建音频播放器
  StreamAudio(
    NUM_CHANNELS: any,
    NUM_SAMPLES: any,
    SAMPLE_RATE: any,
    AUDIO_CHUNKS: any,
  ) {
    const audioBuffer = audioCtx.createBuffer(
      NUM_CHANNELS,
      NUM_SAMPLES / NUM_CHANNELS,
      SAMPLE_RATE,
    )
    for (let channel = 0; channel < NUM_CHANNELS; channel++) {
      // This gives us the actual ArrayBuffer that contains the data
      const nowBuffering = audioBuffer.getChannelData(channel)
      for (let i = 0; i < NUM_SAMPLES; i++) {
        const order = i * NUM_CHANNELS + channel
        let localSample = 1.0 / 32767.0
        localSample *= AUDIO_CHUNKS[order]
        nowBuffering[i] = localSample
      }
    }

    let startTime = 0
    const source = audioCtx.createBufferSource()
    source.buffer = audioBuffer

    source.connect(audioCtx.destination)
    // navigator.mediaDevices.getUserMedia({ audio: true })
    source.start(startTime)
    console.log('开始播放')

    startTime += audioBuffer.duration
  }
  ProcessAudioData(_byte: any, _GZipMode: any) {
    ReadyToGetFrame_aud = false
    console.log('音频')
    let bytes = new Uint8Array(_byte)
    console.log(bytes, '7777')
    if (_GZipMode) {
      const gunzip = new Zlib.Zlib.Gunzip(bytes)
      bytes = gunzip.decompress()
    }

    const SourceSampleRate = this.ByteToInt32(bytes, 0)
    console.log(SourceSampleRate, '111')
    const SourceChannels = this.ByteToInt32(bytes, 4)

    const BufferData = bytes.slice(8, bytes.length)
    const AudioInt16 = new Int16Array(BufferData.buffer)

    if (AudioInt16.length > 0)
      this.StreamAudio(
        SourceChannels,
        AudioInt16.length,
        SourceSampleRate,
        AudioInt16,
      )
    ReadyToGetFrame_aud = true
  }

  // 视频
  ProcessImageData(_byte: any, _GZipMode: any) {
    ReadyToGetFrame_img = false

    let binary = ''

    let bytes = new Uint8Array(_byte)
    if (_GZipMode) {
      const gunzip = new Zlib.Zlib.Gunzip(bytes)
      bytes = gunzip.decompress()
    }

    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }

    const imgUrl = 'data:image/jpeg;base64,' + btoa(binary)
    console.log(imgUrl)
    store.commit('setVideoUrl', imgUrl)

    ReadyToGetFrame_img = true
  }
  // 页面级权限回调
  rtmPageCallback(newValue: any, oldValue: any) {
    if (newValue.code === 4100 && oldValue.code === 4100) {
      const { userId, content } = newValue
      const _content1 = JSON.parse(content)
      const _content2 = JSON.parse(oldValue.content)
      // 用于判断谁得权限改变了
      const list1 = _content1.filter((item: { Id: any }) => item.Id === userId)
      const list2 = _content2.filter((item: { Id: any }) => item.Id === userId)
      const list3 = this.findChangedProperties(list1[0], list2[0])
      if (list3.length) {
        const { key, value } = list3[0]
        const isMe = store.state.newPersonInfo.id === userId
        switch (key) {
          case 'IsControl':
            if (isMe) {
              HMconfirm(value ? `你已获得操作权限` : `操作权限已被收回`)
            }
            break
          default:
            break
        }
      }
    }
  }
  rtmCallback = (result: string | ArrayBuffer) => {
    if (typeof result === 'string') {
      return {
        content: result,
      }
    } else {
      const bytesArr = new Uint8Array(result)
      const decoder = new TextDecoder('utf-8')
      const code = this.getCode(0, 4, bytesArr)
      if (code === 4100) {
        const userId = this.getCode(4, 8, bytesArr)
        const content = decoder.decode(bytesArr.slice(8, bytesArr.byteLength))
        return {
          code,
          userId,
          content,
        }
      }
    }
    return {}
  }
  rtcCallback = (result: string | ArrayBuffer) => {
    if (typeof result === 'string') {
      return {
        content: result,
      }
    } else {
      const bytesArr = new Uint8Array(result)
      const decoder = new TextDecoder('utf-8')
      const content = decoder.decode(bytesArr.slice(0, bytesArr.byteLength))
      return {
        content,
      }
    }
  }
  rtiCallback = (result: string | ArrayBuffer) => {
    if (typeof result === 'string') {
      return {
        content: result,
      }
    } else {
      const bytesArr = new Uint8Array(result)
      const decoder = new TextDecoder('utf-8')
      const code = this.getCode(0, 4, bytesArr)
      // 服务器已接收请求并执行成功
      if (code === 4200) {
        const version = this.getCode(4, 8, bytesArr)
        const content = decoder.decode(bytesArr.slice(8, bytesArr.byteLength))
        this.versionRti = version
        return {
          code,
          version,
          content,
        }
      }
    }
    return {}
  }
  rtaCallback = (result: string | ArrayBuffer) => {
    if (typeof result === 'string') {
      return {
        content: result,
      }
    } else {
      const bytesArr = new Uint8Array(result)
      const code = this.getCode(0, 4, bytesArr) // 用户音频标签
      const dataId = this.getCode(4, 8, bytesArr)
      if (dataId != dataID_aud) receivedLength_aud = 0

      dataID_aud = dataId
      const dataLength = this.getCode(8, 12, bytesArr) // dataByte 的长度

      const dataOffset = this.getCode(12, 16, bytesArr) // 当前 chunk 在 dataByte 中的偏移量

      const GZipMode = bytesArr[16] == 1 ? true : false // GZipMode 是否压缩，值为 1 或 0

      const placeHolder = bytesArr[17] // 占位符

      if (receivedLength_aud == 0) dataByte_aud = new Uint8Array(0)
      receivedLength_aud += bytesArr.length - 18
      dataByte_aud = this.CombineInt8Array(
        dataByte_aud,
        bytesArr.slice(18, bytesArr.length),
      ) as any

      console.log(
        'code:' + code,
        'dataId:' + dataId,
        'dataLength:' + dataLength,
        'dataOffset:' + dataOffset,
        'GZipMode:' + GZipMode,
        'placeHolder:' + placeHolder,
      )
      return {
        code,
        dataId,
        dataLength,
        dataOffset,
        GZipMode,
        placeHolder,
        dataByte_aud,
      }
    }
  }
  rtvCallback = (result: string | ArrayBuffer) => {
    if (typeof result === 'string') {
      return {
        content: result,
      }
    } else {
      const bytesArr = new Uint8Array(result)
      const code = this.getCode(0, 4, bytesArr) // 用户音频标签
      const dataId = this.getCode(4, 8, bytesArr)
      if (dataId != dataID_img) receivedLength_img = 0

      dataID_img = dataId
      const dataLength = this.getCode(8, 12, bytesArr) // dataByte 的长度

      const dataOffset = this.getCode(12, 16, bytesArr) // 当前 chunk 在 dataByte 中的偏移量

      const GZipMode = bytesArr[16] == 1 ? true : false // GZipMode 是否压缩，值为 1 或 0

      const placeHolder = bytesArr[17] // 占位符

      if (receivedLength_img == 0) dataByte_img = new Uint8Array(0)
      receivedLength_img += bytesArr.length - 18
      dataByte_img = this.CombineInt8Array(
        dataByte_img,
        bytesArr.slice(18, bytesArr.length),
      ) as any

      console.log(
        'code:' + code,
        'dataId:' + dataId,
        'dataLength:' + dataLength,
        'dataOffset:' + dataOffset,
        'GZipMode:' + GZipMode,
        'placeHolder:' + placeHolder,
      )
      return {
        code,
        dataId,
        dataLength,
        dataOffset,
        GZipMode,
        placeHolder,
        dataByte_img,
      }
    }
  }
  knowledgeStatus(content: any) {
    const { data } = content
    const _data = JSON.parse(data)
    const { arg2, arg1 } = _data
    const state = arg2.Item2
    if (arg1) {
      const name = JSON.parse(arg2.Item1)
    }
    if (state) {
      // 显示
    } else {
      // 隐藏
    }
  }
  // 拆分动画指令
  splitAnimation(content: { msgId: number; data: string }) {
    const signals = this.Editor.signals
    if (content.msgId === 93) {
      // 拆分
      signals.decomposeAnimation.dispatch()
    } else if (content.msgId === 94) {
      // 组合
      signals.composeAnimation.dispatch()
    } else if (content.msgId === 9) {
      // 选中
      const nodeName = JSON.parse(content.data).arg2
      if (nodeName) {
        this.Editor.selectNode(nodeName)
      } else {
        this.Editor.selectNode(null)
      }
    } else if (content.msgId === 74) {
      // 知识点详情的显隐
      this.knowledgeStatus(content)
    } else if (content.msgId === 101 || content.msgId === 102) {
      // 打开知识点-101;关闭知识点-102
      signals.handleZhishidianBtn.dispatch(content.msgId)
    } else if (content.msgId === 105) {
      // 重置
      signals.composeAnimationAll.dispatch()
    }
  }
  setPosition(
    vector4: { x: number; y: number; z: number; w: number },
    vector3: { x: number; y: number; z: number },
  ) {
    console.log(vector4, 'vector4')

    // Unity 中的四元数旋转
    const unityQuaternion = new Quaternion(
      vector4.x,
      vector4.y,
      vector4.z,
      vector4.w,
    )
    // // 转换为 Three.js 的四元数
    const threeQuaternion = new THREE.Quaternion(
      unityQuaternion.x * -1,
      unityQuaternion.y,
      unityQuaternion.z,
      unityQuaternion.w * -1,
    )
    const pv = new THREE.Euler()
    pv.setFromQuaternion(threeQuaternion)
    pv.y += Math.PI // Y is 180 degrees off
    pv.z *= -1 // flip Z
    // // // 创建 Three.js 的旋转矩阵
    // const rotationMatrix = new THREE.Matrix4().makeRotationFromQuaternion(
    //   threeQuaternion,
    // )
    this.Editor.camera.position.set(vector3.x * -1, vector3.y, vector3.z)
    // this.Editor.camera.applyMatrix4(rotationMatrix)
    this.Editor.camera.rotation.copy(pv)
    this.Editor.signals.cameraChanged.dispatch()
  }
  handleRtcCallback(content: { senderId: number; data: string }) {
    // console.log('同步', store.getters.IsMainScreenUser)
    if (
      store.getters.IsMainScreenUser.length &&
      store.getters.IsMainScreenUser[0].Id === content.senderId
    ) {
      // console.log('同步画面', JSON.parse(content.data))
      const __data = JSON.parse(content.data)
      this.setPosition(__data.v4, __data.v3)
    }
  }
  initWebSocket() {
    this.websocket = new WebSocket(this.url)
    // 处理对应的连接事件
    this.websocket.addEventListener('open', () => {
      console.log(`WebSocket ${this.url} 连接已建立`)
      // 建立连接后启动心跳定时器
      this.heartbeatTimer = setInterval(() => {
        this.send(this.setByte(4000))
      }, this.time)
    })
    this.websocket.addEventListener('close', (e) => {
      console.log(e)
      console.log(`WebSocket ${this.url} 连接已关闭`)
      const content = e.reason || '连接已关闭'
      message.error(content)
      // 关闭连接时清除心跳定时器
      this.clearHeartbeat()
    })
    this.websocket.addEventListener('error', (error) => {
      console.log(`WebSocket ${this.url} 出错了: ${error}`)
      // 出错时清除心跳定时器
      this.clearHeartbeat()
    })
    this.websocket.addEventListener('message', async (event) => {
      if (this.name === 'rtm') {
        // 如果是房间通道
        const res: any = await this.getByte(event.data, this.rtmCallback)
        console.log(res, '?res-rtm')
        this.rtmPageCallback(res, this.rtmContent)
        this.rtmContent = res
        if (res.content && typeof res.content === 'string') {
          store.commit('setRoomList', JSON.parse(res.content))
        }
      } else if (this.name === 'rti') {
        // 如果是互动通道（拆分）
        const res: any = await this.getByte(event.data, this.rtiCallback)

        this.rimContent = res
        if (res.content) {
          const content = JSON.parse(res.content)
          this.rimContent.content = content
          this.splitAnimation(content.data)
        }
      } else if (this.name === 'rtc') {
        // 如果是帧同步通道（移动旋转缩放）
        const res: any = await this.getByte(event.data, this.rtcCallback)
        console.log(res, 'resres')
        if (res.content) {
          const content = JSON.parse(res.content)
          this.handleRtcCallback(content)
        }
      } else if (this.name === 'rta') {
        // 如果是音頻通道
        const res: any = await this.getByte(event.data, this.rtaCallback)
        if (ReadyToGetFrame_aud) {
          if (receivedLength_aud == res.dataLength) {
            this.ProcessAudioData(res.dataByte_aud, res.GZipMode)
          }
        }
      } else if (this.name === 'rtv') {
        // 如果是视频通道
        console.log(`WebSocket-rtv 收到消息${event.data} `)
        const res: any = await this.getByte(event.data, this.rtvCallback)
        console.log(res)
        if (ReadyToGetFrame_img) {
          if (receivedLength_img == res.dataLength) {
            this.ProcessImageData(res.dataByte_img, res.GZipMode)
          }
        }
      } else {
        console.log(`WebSocket 收到消息${event.data} `)
      }
    })
  }
  sendRtm(message: { userId: number; cmd: any }) {
    const userId = this.setByte(message.userId)
    // const jsonString = JSON.stringify(message.cmd).replace(/"/g, '\\"')
    const jsonString = JSON.stringify(message.cmd)
    const cmd = this.setEncoderByte(jsonString)
    const content = new Uint8Array([...userId, ...cmd])
    this.send(content)
  }
  sendRti(msgId: number, nodeName?: string) {
    if (this.rimContent !== null) {
      const { content } = this.rimContent
      const { data } = this.rimContent.content
      content.version++
      const _version = this.setByte(content.version)
      data.senderId = store.state.newPersonInfo.id
      data.msgId = msgId
      const _data: any = {}
      // 选中
      if (msgId === 9) {
        _data.arg1 = data.senderId
        _data.arg2 = nodeName
        _data.msgId = msgId
        data.data = JSON.stringify(_data)
      }
      const sendContent = Array.from(
        this.setEncoderByte(JSON.stringify(content)),
      )
      const _sendContent = new Uint8Array([..._version, ...sendContent])
      this.send(_sendContent)
    }
  }
  sendRtc(msgId: number, senderId: number, v3: Vector3, v4: Vector4) {
    const rotationData = {
      arg: senderId,
      v3,
      v4: {
        x: v4.x,
        y: v4.y,
        z: v4.z,
        w: v4.w,
      },
      msgId,
    }
    const content = {
      senderId,
      data: JSON.stringify(rotationData),
      msgId,
    }
    console.log(JSON.stringify(content), '我发送的数据')
    const sendContent = Array.from(this.setEncoderByte(JSON.stringify(content)))
    const _sendContent = new Uint8Array([...sendContent])
    this.send(_sendContent)
  }

  sendRta(message: {
    label: number
    data: any
    dataOffset: number
    GZipMode: number
    aud: any
  }) {
    console.log('发送音频', message)
    const buffer = new ArrayBuffer(1) // 创建一个 4 字节大小的 ArrayBuffer 对象
    const bytes = new Uint8Array(buffer)
    const label = this.setByte(message.label)
    const dataID = this.setByte(dataId)
    const a = this.setByte(11025) // 采样率
    const b = this.setByte(1) // 声道
    const c = this.setByte(1) // 发送频率
    const dataOffset = this.setByte(message.dataOffset)
    const GZipMode = bytes
    const placeHolder = bytes
    const content = new Uint8Array(message.aud)
    const _dataLength = new Uint8Array([...a, ...b, ...c, ...content])
    const dataLength = this.setByte(_dataLength.length)
    const _sendContent = new Uint8Array([
      ...label,
      ...dataID,
      ...dataLength,
      ...dataOffset,
      ...GZipMode,
      ...placeHolder,
      ..._dataLength,
    ])
    console.log(_sendContent, '_sendContent', _sendContent.length)
    this.send(_sendContent)
    if (dataId >= 1024) {
      dataId = 0
    } else {
      dataId++
    }
  }
  send(message: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(message)
    }
  }
  close() {
    if (this.websocket) {
      this.websocket.close()
    }
  }
  clearHeartbeat() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer)
      this.heartbeatTimer = null
    }
  }
}

export default WebSocketInstance
