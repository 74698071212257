import { AddObjectCommand } from './AddObjectCommand'
import { Command } from './Command'
import { SetUuidCommand } from './SetUuidCommand'
import { SetValueCommand } from './SetValueCommand'

class SetSceneCommand extends Command {
  constructor(editor, scene) {
    super(editor)
    this.type = 'SetSceneCommand'
    this.name = '场景设置'
    this.cmdArray = []
    if (scene !== undefined) {
      this.cmdArray.push(
        new SetUuidCommand(this.editor, this.editor.scene, scene.uuid),
      )
      this.cmdArray.push(
        new SetValueCommand(this.editor, this.editor.scene, 'name', scene.name),
      )
      this.cmdArray.push(
        new SetValueCommand(
          this.editor,
          this.editor.scene,
          'userData',
          JSON.parse(JSON.stringify(scene.userData)),
        ),
      )

      while (scene.children.length > 0) {
        const child = scene.children.pop()
        this.cmdArray.push(new AddObjectCommand(this.editor, child))
      }
    }
  }
  execute() {
    this.editor.signals.sceneGraphChanged.active = false
    for (let i = 0; i < this.cmdArray.length; i++) {
      this.cmdArray[i].execute()
    }
    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  }
  undo() {
    this.editor.signals.sceneGraphChanged.active = false
    for (let i = this.cmdArray.length - 1; i >= 0; i--) {
      this.cmdArray[i].undo()
    }

    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  }
}

export { SetSceneCommand }
