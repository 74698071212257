import * as THREE from 'three'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { initBoxGeometry } from './Ui.three'
import { AddObjectCommand } from './commands/AddObjectCommand'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { SetSceneCommand } from './commands/SetSceneCommand'
import { message } from 'ant-design-vue'

function Loader(editor) {
  let scope = this
  let signals = editor.signals
  this.texturePath = ''
  this.createFilesMap = (files) => {
    let map = {}
    for (let i = 0; i < files.length; i++) {
      map[files.name] = files[i]
    }
    return map
  }
  this.setAnimaition = (result) => {
    if (result.animations.length > 0) {
      editor.animations.push(...result.animations)
      editor.animationControl.initAddAnimation(result.animations)
    }
  }

  // 判断两棵树是否有重名
  this.hasDuplicateName = (tree1, tree2) => {
    const set = new Set()
    // 深度优先搜索函数
    function dfs(node) {
      if (set.has(node.name)) {
        return true // 存在重名，立即返回true
      }
      set.add(node.name)
      for (let child of node.children) {
        if (dfs(child)) {
          return true // 子树中存在重名，立即返回true
        }
      }
      return false
    }
    return dfs(tree1) || dfs(tree2)
  }
  this.loaderFiles = (files) => {
    if (files.length > 0) {
      let filesMap = this.createFilesMap(files)
      let manager = new THREE.LoadingManager()
      manager.setURLModifier((url) => {
        url = url.replace(/^(\.?\/)/, '')
        let file = filesMap[url]
        if (file) {
          return URL.createObjectURL(files[url])
        }
        return url
      })
      for (let i = 0; i < files.length; i++) {
        this.loadFile(files[i], manager)
      }
    }
  }
  this.loadFile = (file, manager) => {
    let fileNameIndex = file.name.lastIndexOf('.')
    let fileName = file.name.substring(0, fileNameIndex)
    let extension = file.name.split('.').pop().toLowerCase()
    let reader = new FileReader()
    reader.addEventListener('progress', (event) => {
      let size = `(${Math.floor(event.total / 1000)} KB)`
      let progress = Math.floor(event.loaded / event.total) * 100 + '%'
      console.log('Loading', fileName, size, progress)
    })
    switch (extension) {
      case 'fbx':
        reader.addEventListener('load', (event) => {
          let contents = event.target.result
          let loader = new FBXLoader(manager)
          let object = loader.parse(contents)
          object.name = fileName
          if (this.hasDuplicateName(editor.scene, object)) {
            message.warn('该文件存在重名，无法导入')
            return
          }
          // this.setAnimaition(object)
          // editor.scene.add(object)
          editor.execute(
            new AddObjectCommand(editor, object, true, object.animations),
          )
        })
        reader.readAsArrayBuffer(file)
        break

      case 'glb':
        reader.addEventListener('load', (event) => {
          let contents = event.target.result
          let dracoLoader = new DRACOLoader()
          dracoLoader.setDecoderPath('./libs/draco/gltf/')

          let loader = new GLTFLoader()
          loader.setDRACOLoader(dracoLoader)
          loader.parse(contents, '', (result) => {
            let scene = result.scene
            scene.name = fileName
            if (this.hasDuplicateName(editor.scene, scene)) {
              message.warn('该文件存在重名，无法导入')
              return
            }
            // this.setAnimaition(result)
            // scene.animations.push(...result.animations)
            editor.execute(
              new AddObjectCommand(editor, scene, true, result.animations),
            )
          })
        })
        reader.readAsArrayBuffer(file)
        break

      case 'gltf':
        reader.addEventListener('load', (event) => {
          let contents = event.target.result
          let loader
          if (isGLTF1(contents)) {
            message.warn('无法导入该文件，仅支持>=2.0的版本')
          } else {
            let dracoLoader = new DRACOLoader()
            dracoLoader.setDecoderPath('./libs/draco/gltf/')

            loader = new GLTFLoader(manager)
            loader.setDRACOLoader(dracoLoader)
          }
          loader.parse(contents, '', (result) => {
            let scene = result.scene
            scene.name = fileName
            if (this.hasDuplicateName(editor.scene, scene)) {
              message.warn('该文件存在重名，无法导入')
              return
            }
            // this.setAnimaition(result)
            editor.execute(
              new AddObjectCommand(editor, scene, true, result.animations),
            )
          })
        })
        reader.readAsArrayBuffer(file)
        break

      case 'obj':
        reader.addEventListener(
          'load',
          async function (event) {
            const contents = event.target.result

            const { OBJLoader } = await import(
              'three/addons/loaders/OBJLoader.js'
            )
            const object = new OBJLoader().parse(contents)
            object.name = fileName

            editor.execute(new AddObjectCommand(editor, object))
          },
          false,
        )
        reader.readAsText(file)

        break

      case 'json':
        reader.addEventListener('load', (event) => {
          let contents = event.target.result

          if (contents.indexOf('postMessage') !== -1) {
            let blob = new Blob([contents], { type: 'text/javascript' })
            let url = URL.createObjectURL(blob)

            let worker = new Worker(url)
            worker.onmessage = function (event) {
              event.data.metadata = { version: 2 }
              handleJSON(event.json)
            }

            worker.postMessage(Date.now())
            return
          }

          let data
          try {
            data = JSON.parse(contents)
          } catch (error) {
            message.error(error)
            return
          }
          handleJSON(data)
        })
        reader.readAsText(file)
        break

      default:
        message.warn('暂不支持，请理解~')
        break
    }
  }
  // 判断gltf格式是否合法
  function isGLTF1(contents) {
    let resultContent
    if (typeof contents === 'string') {
      resultContent = contents
    } else {
      let magic = THREE.LoaderUtils.decodeText(new Uint8Array(contents, 0, 4))
      if (magic === 'glTF') {
        let version = new DataView(contents).getUint32(4, true)
        return version < 2
      } else {
        resultContent = THREE.LoaderUtils.decodeText(new Uint8Array(contents))
      }
    }
    let json = JSON.parse(resultContent)
    return json.asset != undefined && json.asset.version[0] < 2
  }

  async function handleJSON(data) {
    if (data.camera) {
      handleJSON(data.camera)
    }
    if (data.metadata === undefined) {
      // 2.0

      data.metadata = { type: 'Geometry' }
    }

    if (data.metadata.type === undefined) {
      // 3.0

      data.metadata.type = 'Geometry'
    }

    if (data.metadata.formatVersion !== undefined) {
      data.metadata.version = data.metadata.formatVersion
    }

    switch (data.metadata.type.toLowerCase()) {
      case 'buffergeometry':
        var loader = new THREE.BufferGeometryLoader()
        var result = loader.parse(data)

        var mesh = new THREE.Mesh(result)

        editor.execute(new AddObjectCommand(editor, mesh, true))
        break

      case 'geometry':
        console.error('Loader: "Geometry" is no longer supported.')

        break

      case 'object':
        if (data.object.type === 'PerspectiveCamera') {
          var loader = new THREE.ObjectLoader()
          loader.setResourcePath(scope.texturePath)
          loader.parse(data, function (result) {
            editor.camera.copy(result)
            editor.signals.windowResize.dispatch()
            // console.log(editor.camera)
          })
        } else {
          var loader = new THREE.ObjectLoader()
          loader.setResourcePath(scope.texturePath)

          const _result = await loader.parseAsync(data)
          if (_result.isScene) {
            editor.execute(new SetSceneCommand(editor, _result))
            editor.signals.addSceneLight.dispatch()
          } else {
            editor.execute(new AddObjectCommand(editor, _result, true))
          }
        }

        break

      case 'app':
        editor.fromJSON(data)

        break
    }
  }
  this.handleJSON = handleJSON
}

export { Loader }
