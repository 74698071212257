import { message } from 'ant-design-vue'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader'
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader'
class UIElement {
  constructor(dom) {
    this.dom = dom
  }
  add() {
    for (let i = 0; i < arguments.length; i++) {
      const argument = arguments[i]
      if (argument instanceof UIElement) {
        this.dom.appendChild(argument.dom)
      } else {
        console.log('UIElement', argument, 'is not an instance of UIElement.')
      }
    }
    return this
  }
  remove() {
    for (let i = 0; i < arguments.length; i++) {
      const argument = arguments[i]
      if (argument instanceof UIElement) {
        this.dom.removeChild(argument.dom)
      } else {
        console.log('UIElement', argument, 'is not an instance of UIElement.')
      }
    }
    return this
  }
  clear() {
    while (this.dom.children.length) {
      this.dom.removeChild(this.dom.lastChild)
    }
  }
  setId(id) {
    this.dom.id = id
    return this
  }
  getId() {
    return this.dom.id
  }
  setClass(name) {
    this.dom.className = name
    return this
  }
  addClass(name) {
    this.dom.classList.add(name)
    return this
  }
  hasClass(name) {
    return this.dom.className.indexOf(name) > -1
  }
  removeClass(name) {
    this.dom.classList.remove(name)
    return this
  }
  setStyle(style, array) {
    for (let i = 0; i < array.length; i++) {
      this.dom.style[style] = array[i]
    }
    return this
  }
  setDisabled(value) {
    this.dom.disabled = value
    return this
  }
  setTextContent(value) {
    this.dom.textContent = value
    return this
  }
  setInnerHTML(value) {
    this.dom.innerHTML = value
  }
}

// 属性
const properties = [
  'position',
  'left',
  'top',
  'right',
  'bottom',
  'width',
  'height',
  'border',
  'borderLeft',
  'borderTop',
  'borderRight',
  'borderBottom',
  'borderColor',
  'display',
  'overflow',
  'margin',
  'marginLeft',
  'marginTop',
  'marginRight',
  'marginBottom',
  'padding',
  'paddingLeft',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'verticalAlign',
  'color',
  'background',
  'backgroundColor',
  'opacity',
  'fontSize',
  'fontWeight',
  'textAlign',
  'textDecoration',
  'textTransform',
  'cursor',
  'zIndex',
]

properties.forEach((property) => {
  const method =
    'set' +
    property.substring(0, 1).toUpperCase() +
    property.substring(1, property.length)
  UIElement.prototype[method] = function () {
    this.setStyle(property, arguments)
    return this
  }
})

// 事件
const events = [
  'KeyUp',
  'KeyDown',
  'MouseOver',
  'MouseOut',
  'MouseDown',
  'MouseUp',
  'Click',
  'DblClick',
  'Change',
  'Input',
  'Focus',
  'Blur',
  'TouchStart',
  'TouchEnd',
  'Wheel',
]

events.forEach((event) => {
  const method = 'on' + event
  UIElement.prototype[method] = function (callback) {
    this.dom.addEventListener(event.toLowerCase(), callback.bind(this), false)
    return this
  }
})

class UIDiv extends UIElement {
  constructor() {
    super(document.createElement('div'))
  }
}

class UISpan extends UIElement {
  constructor() {
    super(document.createElement('span'))
  }
}

class UIPanel extends UIDiv {
  constructor() {
    super()
    this.dom.className = 'panel'
    this.options = []
  }
}

class UISelect extends UIElement {
  constructor(dom) {
    super(dom)
  }
  setOptions(options) {
    const selected = this.dom.value
    this.options = options

    while (this.dom.children.length > 0) {
      this.dom.removeChild(this.dom.firstChild)
    }
    for (const key in options) {
      const option = document.createElement('option')
      option.value = key
      option.innerHTML = options[key]
      this.dom.appendChild(option)
    }
    this.dom.value = selected
    return this
  }
  setValue(value) {
    value = String(value)
  }
  getIndex() {
    let keys = Object.keys(this.options)
    return keys.findIndex((item) => item == this.dom.value)
  }
}

class UISelectInit extends UISelect {
  constructor() {
    super(document.createElement('select'))
    this.dom.setAttribute('autocomplete', 'off')
  }
  setMultiple(boolean) {
    this.dom.multiple = boolean
    return false
  }
  getValue() {
    return this.dom.value
  }
  setValue(value) {
    value = String(value)
    if (this.dom.value !== value) {
      this.dom.value = value
    }
    return this
  }
}

class UIText extends UISpan {
  constructor(text) {
    super()

    this.dom.className = 'text'
    this.dom.style.cursor = 'default'
    this.dom.style.display = 'inline-block'

    this.setValue(text)
  }

  setValue(value) {
    if (value !== undefined) {
      this.dom.textContent = value
    }
    return this
  }
  getValue() {
    return this.dom.textContent
  }
}

// 可编辑的文本框
class UITextEdit extends UIText {
  constructor(text) {
    super()
    this.setValue(text)
    this.setMaxLength(20)
    this.onInput(function () {
      console.log('dkdk')
    })
  }
  setMaxLength(maxLen) {
    this.dom.maxLength = maxLen
    return this
  }
  focusAtEnd() {
    const range = document.createRange()
    range.selectNodeContents(this.dom)
    range.collapse(false)

    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }
  setEdit(isEdit, msg = '操作有误') {
    if (isEdit) {
      this.dom.contentEditable = true
      this.dom.focus()
      this.focusAtEnd()
    } else {
      const _txt = this.getValue()
      if (_txt.length > 0) {
        this.dom.contentEditable = false
      } else {
        message.warn(msg)
        this.focusAtEnd()
      }
    }
  }
}

class UIBreak extends UIElement {
  constructor() {
    super(document.createElement('br'))
    this.dom.className = 'break'
  }
}

class UIMenuOption extends UIDiv {
  constructor(text, id) {
    super()
    this.dom.id = id
    this.dom.className = 'option'
    this.setValue(text)
  }
  setValue(value) {
    if (value !== undefined) {
      this.dom.textContent = value
    }
    return this
  }
  getValue() {
    return this.dom.textContent
  }
}

class UIIcon extends UIElement {
  constructor(className) {
    super(document.createElement('i'))
    this.dom.className = `iconfont ${className}`
  }
  addClassName(className) {
    let name = className
    if (typeof className === 'string') {
      name = className.split(' ')
    }
    while (name.length) {
      this.dom.addClass(name[i])
      name.splice(0, 1)
    }
  }
}

class UIIconUnic extends UIElement {
  constructor(value) {
    super(document.createElement('i'))
    this.dom.className = `iconfont`
    this.dom.textContent = value
  }
  addClassName(className) {
    let name = className
    if (typeof className === 'string') {
      name = className.split(' ')
    }
    while (name.length) {
      this.dom.addClass(name[i])
      name.splice(0, 1)
    }
  }
  setTitle(value) {
    this.dom.title = value
    return this
  }
}

/**
 * 输入框
 * @param {string} value
 * @param {boolean} [hasEnterEvent = false] 是否开启回车事件
 * @param {boolean} [isNull = true] 是否允许为空
 */
class UIInput extends UIElement {
  constructor(value, hasEnterEvent = false, isNull = true) {
    super(document.createElement('input'))
    this.dom.className = 'input'
    this.dom.type = 'text'
    this.value = ''
    this.oldValue = ''
    this.dom.setAttribute('autocomplete', 'off')
    this.openEnter = hasEnterEvent
    let scope = this
    this.setValue(value)

    function onChange() {
      scope.oldValue = scope.getValue()
      const _value = scope.dom.value
      if (isNull === false && _value.length === 0) {
        scope.dom.focus()
        scope.dom.style.borderColor = 'red'
      } else {
        scope.dom.style.borderColor = 'transparent'
      }
      scope.setValue(_value)
    }

    function onKeyDown(event) {
      event.stopPropagation()
      switch (event.keyCode) {
        case 13:
          scope.dom.blur()
          break
      }
    }

    this.dom.addEventListener('input', onChange, false)
    this.dom.addEventListener('change', onChange, false)
    if (this.openEnter) {
      this.dom.addEventListener('keydown', onKeyDown)
    }
  }
  setPlaceholder(placeholderValue) {
    this.dom.placeholder = placeholderValue
    return this
  }
  setValue(value) {
    if (value !== undefined) {
      this.dom.value = value
      this.value = value
      this.setDisabled(value === 'Scene' || value === 'Camera')
    }
    return this
  }
  getValue() {
    return this.value
  }
  setMaxLength(step) {
    this.dom.maxLength = step
    return this
  }
  setDisabled(disabled = false) {
    this.dom.disabled = disabled
    if (disabled) {
      this.dom.classList.add('is-txt')
    } else {
      this.dom.classList.remove('is-txt')
    }
  }
}

class UICheckbox extends UIElement {
  constructor(value) {
    super(document.createElement('input'))
    this.dom.type = 'checkbox'
    this.checked = true
    this.setValue(value)
    let scope = this
    function onChange() {
      scope.setValue(scope.dom.checked)
    }
    this.dom.addEventListener('change', onChange)
  }
  getValue() {
    return this.dom.checked
  }
  setValue(value) {
    if (value !== undefined) {
      this.dom.checked = value
      this.checked = value
    }
    return this
  }
}

class UIColor extends UIElement {
  constructor() {
    super(document.createElement('input'))

    this.dom.className = 'Color'
    this.dom.style.width = '32px'
    this.dom.style.height = '16px'
    // this.dom.style.border = '0px'
    this.dom.style.padding = '2px'
    this.dom.style.backgroundColor = 'transparent'

    this.dom.setAttribute('autocomplete', 'off')

    try {
      this.dom.type = 'color'
      this.dom.value = '#ffffff'
    } catch (exception) {}
  }
  getValue() {
    return this.dom.value
  }
  getHexValue() {
    return parseInt(this.dom.value.substr(1), 16)
  }
  setValue(value) {
    this.dom.value = value
    return this
  }
  setHexValue(hex) {
    this.dom.value = '#' + ('000000' + hex.toString(16)).slice(-6)
    return this
  }
}

class UINumber extends UIElement {
  constructor(number) {
    super(document.createElement('input'))

    this.dom.style.cursor = 'ns-resize'
    this.dom.className = 'Number'
    this.dom.value = '0.00'

    this.dom.setAttribute('autocomplete', 'off')

    this.value = 0

    this.min = -Infinity
    this.max = Infinity

    this.precision = 2
    this.step = 1
    this.unit = ''
    this.nudge = 0.01

    this.setValue(number)

    const scope = this

    const changeEvent = document.createEvent('HTMLEvents')
    changeEvent.initEvent('change', true, true)

    let distance = 0
    let onMouseDownValue = 0

    const pointer = { x: 0, y: 0 }
    const prevPointer = { x: 0, y: 0 }

    function onMouseDown(event) {
      event.preventDefault()

      distance = 0

      onMouseDownValue = scope.value

      prevPointer.x = event.clientX
      prevPointer.y = event.clientY

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }

    function onMouseMove(event) {
      const currentValue = scope.value

      pointer.x = event.clientX
      pointer.y = event.clientY

      distance += pointer.x - prevPointer.x - (pointer.y - prevPointer.y)

      let value =
        onMouseDownValue + (distance / (event.shiftKey ? 5 : 50)) * scope.step
      value = Math.min(scope.max, Math.max(scope.min, value))

      if (currentValue !== value) {
        scope.setValue(value)
        scope.dom.dispatchEvent(changeEvent)
      }

      prevPointer.x = event.clientX
      prevPointer.y = event.clientY
    }

    function onMouseUp() {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)

      if (Math.abs(distance) < 2) {
        scope.dom.focus()
        scope.dom.select()
      }
    }

    function onTouchStart(event) {
      if (event.touches.length === 1) {
        distance = 0

        onMouseDownValue = scope.value

        prevPointer.x = event.touches[0].pageX
        prevPointer.y = event.touches[0].pageY

        document.addEventListener('touchmove', onTouchMove)
        document.addEventListener('touchend', onTouchEnd)
      }
    }

    function onTouchMove(event) {
      const currentValue = scope.value

      pointer.x = event.touches[0].pageX
      pointer.y = event.touches[0].pageY

      distance += pointer.x - prevPointer.x - (pointer.y - prevPointer.y)

      let value =
        onMouseDownValue + (distance / (event.shiftKey ? 5 : 50)) * scope.step
      value = Math.min(scope.max, Math.max(scope.min, value))

      if (currentValue !== value) {
        scope.setValue(value)
        scope.dom.dispatchEvent(changeEvent)
      }

      prevPointer.x = event.touches[0].pageX
      prevPointer.y = event.touches[0].pageY
    }

    function onTouchEnd(event) {
      if (event.touches.length === 0) {
        document.removeEventListener('touchmove', onTouchMove)
        document.removeEventListener('touchend', onTouchEnd)
      }
    }

    function onChange() {
      scope.setValue(scope.dom.value)
    }

    function onFocus() {
      scope.dom.style.backgroundColor = ''
      scope.dom.style.cursor = ''
    }

    function onBlur() {
      scope.dom.style.backgroundColor = 'transparent'
      scope.dom.style.cursor = 'ns-resize'
    }

    function onKeyDown(event) {
      event.stopPropagation()

      switch (event.keyCode) {
        case 13: // enter
          scope.dom.blur()
          break

        case 38: // up
          event.preventDefault()
          scope.setValue(scope.getValue() + scope.nudge)
          scope.dom.dispatchEvent(changeEvent)
          break

        case 40: // down
          event.preventDefault()
          scope.setValue(scope.getValue() - scope.nudge)
          scope.dom.dispatchEvent(changeEvent)
          break
      }
    }

    onBlur()

    this.dom.addEventListener('keydown', onKeyDown)
    this.dom.addEventListener('mousedown', onMouseDown)
    this.dom.addEventListener('touchstart', onTouchStart, { passive: false })
    this.dom.addEventListener('change', onChange)
    this.dom.addEventListener('focus', onFocus)
    this.dom.addEventListener('blur', onBlur)
  }

  getValue() {
    return this.value
  }

  setValue(value) {
    if (value !== undefined) {
      value = parseFloat(value)

      if (value < this.min) value = this.min
      if (value > this.max) value = this.max

      this.value = value
      this.dom.value = value.toFixed(this.precision)

      if (this.unit !== '') this.dom.value += ' ' + this.unit
    }

    return this
  }

  setPrecision(precision) {
    this.precision = precision

    return this
  }

  setStep(step) {
    this.step = step

    return this
  }

  setNudge(nudge) {
    this.nudge = nudge

    return this
  }

  setRange(min, max) {
    this.min = min
    this.max = max

    return this
  }

  setUnit(unit) {
    this.unit = unit

    return this
  }
}

class UITexture extends UISpan {
  constructor(mapping) {
    super()
    const scope = this
    const form = document.createElement('form')
    const input = document.createElement('input')
    input.type = 'file'
    input.addEventListener('change', function (event) {
      loadFile(event.target.files[0])
    })
    form.appendChild(input)

    const canvas = document.createElement('canvas')
    canvas.width = 32
    canvas.height = 16
    canvas.style.cursor = 'pointer'
    canvas.style.marginRight = '5px'
    canvas.style.border = '1px solid #888'
    canvas.addEventListener('click', function () {
      input.click()
    })
    canvas.addEventListener('drop', function (event) {
      event.preventDefault()
      event.stopPropagation()
      loadFile(event.dataTransfer.files[0])
    })
    this.dom.appendChild(canvas)

    function loadFile(file) {
      const extension = file.name.split('.').pop().toLowerCase()
      const reader = new FileReader()

      if (extension === 'hdr' || extension === 'pic') {
        reader.addEventListener('load', function (event) {
          // assuming RGBE/Radiance HDR iamge format

          const loader = new RGBELoader()
          loader.load(event.target.result, function (hdrTexture) {
            hdrTexture.sourceFile = file.name
            hdrTexture.isHDRTexture = true

            scope.setValue(hdrTexture)

            if (scope.onChangeCallback) scope.onChangeCallback(hdrTexture)
          })
        })

        reader.readAsDataURL(file)
      } else if (extension === 'tga') {
        reader.addEventListener(
          'load',
          function (event) {
            const canvas = new TGALoader().parse(event.target.result)

            // const texture = new THREE.CanvasTexture(canvas, mapping)
            const texture = new THREE.DataTexture(
              canvas.data,
              canvas.width,
              canvas.height,
              THREE.RGBAFormat,
              THREE.UnsignedByteType,
              mapping,
            )
            texture.needsUpdate = true

            texture.generateMipmaps = true
            // texture.flipY = true
            texture.magFilter = THREE.LinearFilter
            texture.minFilter = THREE.LinearMipmapLinearFilter
            texture.colorSpace = THREE.SRGBColorSpace
            // texture.premultiplyAlpha = true
            texture.transparent = true

            texture.sourceFile = file.name

            scope.setValue(texture)

            if (scope.onChangeCallback) scope.onChangeCallback(texture)
          },
          false,
        )

        reader.readAsArrayBuffer(file)
      } else if (extension === 'dds') {
        reader.addEventListener(
          'load',
          function (event) {
            new DDSLoader().load(event.target.result, function (result) {
              result.sourceFile = file.name
              // 翻转uv
              result.repeat.set(1, -1) // 翻转 Y 轴
              result.offset.set(0, 1) // 偏移 Y 轴
              scope.setValue2(result)
              if (scope.onChangeCallback) scope.onChangeCallback(result)
            })
          },
          false,
        )
        reader.readAsDataURL(file)
      } else if (file.type.match('image.*')) {
        reader.addEventListener(
          'load',
          function (event) {
            const image = document.createElement('img')
            image.addEventListener(
              'load',
              function () {
                const texture = new THREE.Texture(this, mapping)
                texture.sourceFile = file.name
                texture.needsUpdate = true

                scope.setValue(texture)

                if (scope.onChangeCallback) scope.onChangeCallback(texture)
              },
              false,
            )

            image.src = event.target.result
          },
          false,
        )

        reader.readAsDataURL(file)
      }

      form.reset()
    }
    this.texture = null
    this.onChangeCallback = null
  }
  getValue() {
    return this.texture
  }
  setValue(texture) {
    const _this = this
    const canvas = this.dom.children[0]
    const context = canvas.getContext('2d')
    // Seems like context can be null if the canvas is not visible
    if (context) {
      // Always clear the context before set new texture, because new texture may has transparency
      context.clearRect(0, 0, canvas.width, canvas.height)
    }
    if (texture !== null) {
      const image = texture.image
      if (image && image.width > 0) {
        canvas.title = texture.sourceFile
        const scale = canvas.width / image.width
        canvas.style.border = '1px solid #888'
        if (image?.src) {
          context.drawImage(
            image,
            0,
            0,
            image.width * scale,
            image.height * scale,
          )
        } else {
          const canvas2 = renderToCanvas(texture)
          context.drawImage(
            canvas2,
            0,
            0,
            image.width * scale,
            image.height * scale,
          )
        }
      } else {
        // 当为压缩类型的纹理
        if (image?.length > 0) {
          _this.setValue2(texture)
        } else {
          canvas.title = texture.sourceFile + ' (error)'
          canvas.style.border = '1px solid #ff0606'
        }
      }
    } else {
      canvas.title = '暂无'
    }
    this.texture = texture
  }
  setValue2(compressedTexture) {
    const canvas = this.dom.children[0]
    const context = canvas.getContext('2d')

    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height)
    }
    if (compressedTexture !== null) {
      const image = compressedTexture.image
      if (image && (image.width > 0 || image.length > 0)) {
        canvas.title = compressedTexture.sourceFile
        canvas.style.border = '1px solid #888'
      }
      const canvas2 = renderToCanvas2(compressedTexture)
      context.drawImage(canvas2, 0, 0, canvas2.width, canvas2.height)
    } else {
      canvas.title = '暂无'
    }

    this.texture = compressedTexture
  }
  setEncoding(encoding) {
    const texture = this.getValue()

    if (texture !== null) {
      texture.encoding = encoding
    }
    return this
  }

  onChange(callback) {
    this.onChangeCallback = callback
    return this
  }
}

class UITextarea extends UIElement {
  constructor(text) {
    super(document.createElement('textarea'))
    this.dom.className = 'textarea'
    this.dom.rows = 5
    this.setValue(text)
    this.setType()
  }
  setValue(value) {
    this.dom.value = value
    return this
  }
  getValue() {
    return this.dom.value
  }
  setMaxLength(maxLen) {
    this.dom.maxLength = maxLen
    return this
  }
  setType(type) {
    let _type = type ? type : 'hard'
    this.dom.wrap = _type
    return this
  }
}

class UIImg extends UIElement {
  constructor(value) {
    super(document.createElement('img'))
    this.setValue(value)
  }
  setValue(value) {
    this.dom.src = value
    return this
  }
}

class UIInteger extends UIElement {
  constructor(number) {
    super(document.createElement('input'))

    this.dom.style.cursor = 'ns-resize'
    this.dom.className = 'Number'
    this.dom.value = '0'

    this.dom.setAttribute('autocomplete', 'off')

    this.value = 0

    this.min = -Infinity
    this.max = Infinity

    this.step = 1
    this.nudge = 1

    this.setValue(number)

    const scope = this

    const changeEvent = document.createEvent('HTMLEvents')
    changeEvent.initEvent('change', true, true)

    let distance = 0
    let onMouseDownValue = 0

    const pointer = { x: 0, y: 0 }
    const prevPointer = { x: 0, y: 0 }

    function onMouseDown(event) {
      event.preventDefault()

      distance = 0

      onMouseDownValue = scope.value

      prevPointer.x = event.clientX
      prevPointer.y = event.clientY

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }

    function onMouseMove(event) {
      const currentValue = scope.value

      pointer.x = event.clientX
      pointer.y = event.clientY

      distance += pointer.x - prevPointer.x - (pointer.y - prevPointer.y)

      let value =
        onMouseDownValue + (distance / (event.shiftKey ? 5 : 50)) * scope.step
      value = Math.min(scope.max, Math.max(scope.min, value)) | 0

      if (currentValue !== value) {
        scope.setValue(value)
        scope.dom.dispatchEvent(changeEvent)
      }

      prevPointer.x = event.clientX
      prevPointer.y = event.clientY
    }

    function onMouseUp() {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)

      if (Math.abs(distance) < 2) {
        scope.dom.focus()
        scope.dom.select()
      }
    }

    function onChange() {
      scope.setValue(scope.dom.value)
    }

    function onFocus() {
      scope.dom.style.backgroundColor = ''
      scope.dom.style.cursor = ''
    }

    function onBlur() {
      scope.dom.style.backgroundColor = 'transparent'
      scope.dom.style.cursor = 'ns-resize'
    }

    function onKeyDown(event) {
      event.stopPropagation()

      switch (event.keyCode) {
        case 13: // enter
          scope.dom.blur()
          break

        case 38: // up
          event.preventDefault()
          scope.setValue(scope.getValue() + scope.nudge)
          scope.dom.dispatchEvent(changeEvent)
          break

        case 40: // down
          event.preventDefault()
          scope.setValue(scope.getValue() - scope.nudge)
          scope.dom.dispatchEvent(changeEvent)
          break
      }
    }

    onBlur()

    this.dom.addEventListener('keydown', onKeyDown)
    this.dom.addEventListener('mousedown', onMouseDown)
    this.dom.addEventListener('change', onChange)
    this.dom.addEventListener('focus', onFocus)
    this.dom.addEventListener('blur', onBlur)
  }

  getValue() {
    return this.value
  }

  setValue(value) {
    if (value !== undefined) {
      value = parseInt(value)

      this.value = value
      this.dom.value = value
    }

    return this
  }

  setStep(step) {
    this.step = parseInt(step)

    return this
  }

  setNudge(nudge) {
    this.nudge = nudge

    return this
  }

  setRange(min, max) {
    this.min = min
    this.max = max

    return this
  }
}

class UIButton extends UIElement {
  constructor() {
    super(document.createElement('button'))
    this.dom.className = 'btn'
  }
}

let renderer

function renderToCanvas(texture) {
  if (renderer === undefined) {
    renderer = new THREE.WebGLRenderer()
  }

  const image = texture.image

  renderer.setSize(image.width, image.height, false)

  const scene = new THREE.Scene()
  const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1)

  const material = new THREE.MeshBasicMaterial({ map: texture })
  const quad = new THREE.PlaneGeometry(2, 2)
  const mesh = new THREE.Mesh(quad, material)
  scene.add(mesh)

  renderer.render(scene, camera)

  return renderer.domElement
}

function renderToCanvas2(texture) {
  if (renderer === undefined) {
    renderer = new THREE.WebGLRenderer()
  }

  const image = texture.image

  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping

  if (image?.length > 0) {
    // texture.mapping = THREE.CubeReflectionMapping
    renderer.setSize(image[0].width, image[0].height, false)
  } else {
    renderer.setSize(image?.width, image?.height, false)
  }

  const scene = new THREE.Scene()
  const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1)

  // texture.minFilter = texture.magFilter = THREE.LinearFilter
  // texture.colorSpace = THREE.SRGBColorSpace
  // 禁用反转
  const material = new THREE.MeshBasicMaterial({ map: texture })
  const quad = new THREE.PlaneGeometry(2, 2)
  const mesh = new THREE.Mesh(quad, material)
  scene.add(mesh)

  renderer.render(scene, camera)

  return renderer.domElement
}

export {
  UIPanel,
  UISelect,
  UIText,
  UIBreak,
  UIDiv,
  UISpan,
  UIMenuOption,
  UIIcon,
  UIInput,
  UICheckbox,
  UISelectInit,
  UIColor,
  UINumber,
  UITexture,
  UITextarea,
  UIImg,
  UIIconUnic,
  UIInteger,
  UITextEdit,
  UIButton,
}
