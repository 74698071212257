/**
 * 更改对象在场景中的基础属性
 */
import { Command } from "./Command";

class SetValueCommand extends Command {
  constructor(editor, object, attributeName, newValue) {
    super(editor);
    this.type = "SetValueCommand";
    this.object = object;
    if (object !== undefined) {
      this.name = `设置：${attributeName}`;
    }
    this.attributeName = attributeName;
    this.oldValue = object !== undefined ? object[attributeName] : undefined;
    this.newValue = newValue;
  }
  execute() {
    this.object[this.attributeName] = this.newValue;
    this.editor.signals.objectChanged.dispatch(this.object);
  }
  undo() {
    this.object[this.attributeName] = this.oldValue;
    this.editor.signals.objectChanged.dispatch(this.object);
    // this.editor.signals.sceneGraphChanged.dispatch();
  }
  update(cmd) {
    this.newValue = cmd.newValue;
  }
}

export { SetValueCommand };
