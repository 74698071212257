/**
 * 动画列表预览
 */

import { SidebarAnimationControl } from './Sidebar.AnimationControl'
import { UIDiv } from './Ui'

function PreviewAnimation(editor) {
  let signals = editor.signals
  let container = new UIDiv().addClass('animation-preview-box')
  let panelRow = new SidebarAnimationControl(editor)

  // 渲染动画列表面板
  signals.showAnimationPanel.add(() => {
    editor.mainDom.add(container)
    container.add(panelRow)
    editor.scene.traverse((child) => {
      if (child.animations.length) {
        signals.showPreviewAnimationPanel.dispatch(child)
      }
    })
  })

  signals.showPhoneAnimationBtn.add(() => {
    if (container.dom.style.display) {
      container.dom.style.display = ''
    } else {
      container.dom.style.display = 'block'
    }
  })
  return container
}
export { PreviewAnimation }
