import axiosRequest from '../../index'
import Api from '../../api'
import { IParamsSearch } from './types'

// 获取站内信列表
export const httpMailList = (params: IParamsSearch) =>
  axiosRequest.get({
    url: Api.mail.list,
    params,
  })

// 获取站内信消息详情
export const httpMailDes = (params: { id: number }) =>
  axiosRequest.get({
    url: Api.mail.des,
    params,
  })

// 获取未读消息数
export const httpUnreadMail = () =>
  axiosRequest.get({
    url: Api.mail.unread,
  })

// 删除站内消息
export const httpMailDelete = ({ id }: { id: number }) =>
  axiosRequest.delete({
    url: Api.mail.des + `/${id}`,
  })
